import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Cross } from '@app/images'
import { Modal } from '@/components/Modal'
import { Button } from '@/ui'
import styles from './NotificatonDetails.module.scss'
import { useHistory } from 'react-router-dom'
import { etdCodesAndLocal, ekdContract } from '@features/notifications/modal/constants'
import { renderWithHTML } from '@/utils'
import { equipment1Selectors, equipment1Thunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import cl from 'classnames'
import { message } from 'antd'
import { LoadingBlocker } from '@/components/Loader'
import { StaffAlertModal } from '@features/occupationalRisks'

const LANG = {
	rus: 'rus',
	kz: 'kz',
}

export const NotificatonDetails = ({ data, visible, onClose }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const [isVisible, setIsVisible] = useState(false)
	const [isVisibleStaffModal, setIsVisibleStaffModal] = useState(false)
	const [modalData, setModaldata] = useState(null)
	const history = useHistory()
	const dispatch = useDispatch()
	const assessmentNotification = useSelector(equipment1Selectors.notification)

	useEffect(() => {
		if (visible) {
			setModaldata(data)
		}
		setIsVisible(visible)
	}, [data, visible])

	const handleCloseModal = useCallback(() => {
		onClose(!!data?.readDate)
	}, [data, onClose])

	const handleOpenAdditional = () => {
		const result = etdCodesAndLocal.find((v) => v.code === modalData?.notifType.code)
		history.push(`/contracts`, { refValue: modalData.contractId, local: result.local })
		onClose()
	}

	const handleOpenTradeUnion = () => {
		history.push(`/my-unions`)
		onClose()
	}

	const handleOpenEkd = () => {
		history.push(`/col-contracts`, { refValue: modalData.contractId, openModal: true })
		onClose()
	}

	const handleAssessment = (isConsent) => {
		let body = {
			data: {
				externalId: modalData?.otherId,
				isConsent: isConsent
			},
			onSuccess: (res) => {
				onClose(true)

				const titleByLang = {
					[LANG.rus]: res?.messageRu,
					[LANG.kz]: res?.messageKz,
				}

				switch (Number(res.code)){
					case 1:
						message.error(titleByLang[language])
						break

					case 2:
						setIsVisibleStaffModal(true)
						message.success(titleByLang[language])
						break

					case 3:
					case 4:
						message.success(titleByLang[language])
						break

					default:
						return
				}
			}
		}
		dispatch(equipment1Thunks.setBranchConsent(body))
	}

	const onSubmitStaff = () => {
		history.push('/staffing')
		setIsVisibleStaffModal(false)
	}

	return (
		<>
			{isVisibleStaffModal &&
				<StaffAlertModal onClose={() => {
					onClose()
					setIsVisibleStaffModal(false)
				}} onSubmit={onSubmitStaff} text={t('occupational_risks_assessment.steps.step_2.info_not_staff_notification')} submitLabel={t('transition.process_button_title')}/>
			}
			{isVisible ? (
				<Modal onCancel={handleCloseModal} windowClassName={styles.modal}>
					<div className={styles.modal_content}>
						<div className={styles.headerButtons}>
							<Button type="bare" onClick={handleCloseModal}>
								<div className={styles.cross}>
									<img src={Cross} alt="cross" />
								</div>
							</Button>
						</div>
						<div className={styles.modal_content_header}>{t('notification')}</div>
						<div className={styles.modal_content_notifType}>
							{(language === 'rus' ? modalData?.notifType?.nameRu : modalData?.notifType?.nameKz) || ''}
						</div>
						<div className={styles.modal_content_notif_date}>
							{modalData?.eventDate?.substring(0, 10)} {modalData?.eventDate?.substring(11, 16)}
						</div>
						<div className={`${styles.modal_content_notif_content} scroll`}>
							{(language === 'rus' ? renderWithHTML(modalData?.notifRu) : renderWithHTML(modalData?.notifKz)) || ''}
						</div>
						<div className={styles.modal_content_footer}>
							{modalData?.notifType?.code === '18' && (
								<div className={styles.modal_button} onClick={handleOpenAdditional}>
									<span>{t('notification_open_contract')}</span>
								</div>
							)}
							{modalData.contractId && etdCodesAndLocal.find((v) => v.code === modalData?.notifType?.code) && (
								<div className={styles.modal_button} onClick={handleOpenAdditional}>
									<span>{t('notification_open_etd')}</span>
								</div>
							)}
							{modalData?.notifType?.code === 'tradeunion.new' && (
								<div className={styles.modal_button} onClick={handleOpenTradeUnion}>
									<span>{t('notification_open_my_unions')}</span>
								</div>
							)}
							{modalData.contractId && ekdContract.find((v) => v.code === modalData?.notifType?.code) && (
								<div className={styles.modal_button} onClick={handleOpenEkd}>
									<span>{t('open_ecd')}</span>
								</div>
							)}
							{modalData?.notifType?.code === 'assessment.staf_request' && (
								<div className={styles.modal_button} onClick={() => handleAssessment(true)}>
									<span>{t('occupational_risks_assessment.accept')}</span>
								</div>
							)}

							{modalData?.notifType?.code === 'assessment.staf_request' ? (
								<div className={styles.modal_button} onClick={() => handleAssessment(false)}>
									<span>{t('labor_records.reject')}</span>
								</div>
							) : (
								<div className={styles.modal_button} onClick={handleCloseModal}>
									<span>{t('close')}</span>
								</div>
							)}
						</div>
					</div>
				</Modal>
			) : null}

			{assessmentNotification.loading && <LoadingBlocker />}
		</>
	)
}
