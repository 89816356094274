import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import Header from '@Root/components/Header/index'
import { Title } from '@Root/components/Typography'
import Tabs, { TabPane } from 'rc-tabs'
import Typography from '@mui/material/Typography'
import { getCurrentLanguage } from '@/i18next'
import { Accordion as MyAccordion } from '../../components/Accordion/Accordion'
import { Input } from '../../components/Input/Input'
import PreHeader from '@/components/home/components/PreHeader'
import SearchIcon from '@mui/icons-material/Search'
import cl from 'classnames'
import Footer from '@features/home/Footer'
import doc from '@/assets/home/doc.svg'
import { AGREEMENT_KEY, AgreementItem } from '@/features'
import { useDispatch, useSelector } from 'react-redux'
import { referenceInformationThunks } from '@/entity/referenceInformation'
import { userSelectors } from '@/entity'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'
import { LoadingBlocker, LoadingScreen } from '@/components/Loader'

const LANG = {
	rus: 'rus',
	kz: 'kz',
}

export const ReferenceInformationPage = () => {
	const { t } = useTranslation()
	const lang = getCurrentLanguage()
	const dispatch = useDispatch()
	let language = getCurrentLanguage()

	const { agreements, download, districts, faq, faqDict } = useSelector((state) => ({
		agreements: state.referenceInformation.agreements,
		download: state.referenceInformation.download,
		districts: state.handbook.districts,
		faq: state.referenceInformation.faq,
		faqDict: state.referenceInformation.faqDict,
	}))


	useEffect(() => {
		dispatch(handbookThunks.getDistricts())
		dispatch(referenceInformationThunks.getAgreementsData())
		dispatch(referenceInformationThunks.getFaqData())
		dispatch(referenceInformationThunks.getFaqDict())
	}, [])


	const [activeAccordion, setActiveAccordion] = useState(null)

	const helpArray = [
		{
			title: t('rules_submitting'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('description_help')}
					</Title>
					<p>{t('order_minister')}</p>
					<Title className={styles.pTop} level={3}>
						{t('link_to_rules')}
					</Title>
					<a
						href="https://adilet.zan.kz/rus/docs/V2000021173"
						target="_blank"
						rel="noopener noreferrer"
						style={{ wordWrap: 'break-word' }}
					>
						https://adilet.zan.kz/rus/docs/V2000021173
					</a>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/rule-rus.pdf' : '../../../files/rule-kz.pdf'} download>
						{t('download_rules')}
					</a>
				</>
			),
		},
		{
			title: t('rules_harmful'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('description_help')}
					</Title>
					<p>{t('order_minister_2')}</p>
					<Title className={styles.pTop} level={3}>
						{t('link_to_rules')}
					</Title>
					<a
						href="https://adilet.zan.kz/rus/docs/V2300033811"
						target="_blank"
						rel="noopener noreferrer"
						style={{ wordWrap: 'break-word' }}
					>
						https://adilet.zan.kz/rus/docs/V2300033811
					</a>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/rulesHarmful-kz.pdf' : '../../../files/rulesHarmful-kz.pdf'}
						download
					>
						{t('download_rules_harmful')}
					</a>
				</>
			),
		},
		{
			title: t('methodology'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={'../../../files/methodology-rus.docx'} download>
						{t('download_methodology')}
					</a>
				</>
			),
		},
	]

	const instructionsArray = [
		{
			title: t('instr.ref_19'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr19-rus.pdf' : '../../../files/instr19-rus.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
				</>
			),
		},
		{
			title: t('instr.ref_15'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr15-rus.pdf' : '../../../files/instr15-kz.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
				</>
			),
		},
		{
			title: t('instr.ref_16'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr16-rus.pdf' : '../../../files/instr16-kz.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
				</>
			),
		},
		{
			title: t('instr.ref_13'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr13-rus.pdf' : '../../../files/instr13-kz.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_13}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_14'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr14.pdf' : '../../../files/instr14-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_16}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_17'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr17.pdf' : '../../../files/instr17-kz.pdf'} download>
						{t('download_instruct')}
					</a>
				</>
			),
		},
		{
			title: t('instr.ref_1'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr1-rus.pdf' : '../../../files/instr1-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_1}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_2'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr2-rus.pdf' : '../../../files/instr2-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_2}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_3'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr3-rus.pdf' : '../../../files/instr3-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_3}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_4'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr4-rus.pdf' : '../../../files/instr4-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_4}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_5'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr5-rus.pdf' : '../../../files/instr5-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_5}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_6'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr6-rus.pdf' : '../../../files/instr6-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_6}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_7'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr7-rus.pdf' : '../../../files/instr7-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_7}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_8'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr8-rus.pdf' : '../../../files/instr8-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_8}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_9'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a href={lang === 'rus' ? '../../../files/instr9-rus.pdf' : '../../../files/instr9-kz.pdf'} download>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_9}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_10'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr10-rus.pdf' : '../../../files/instr10-kz.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_10}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_1'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr11-rus.pdf' : '../../../files/instr11-kz.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_11}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_12'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr12-rus.pdf' : '../../../files/instr12-kz.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
					<Title className={styles.pTop} level={3}>
						{t('video_instruction')}
					</Title>
					<iframe
						src={process.env.REACT_APP_SUPPORT_REF_12}
						frameBorder={0}
						allow="autoplay; encrypted-media"
						allowFullScreen
						title="video"
					/>
					{' '}
				</>
			),
		},
		{
			title: t('instr.ref_18'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('file')}
					</Title>
					<a
						href={lang === 'rus' ? '../../../files/instr18-rus.pdf' : '../../../files/instr18-kz.pdf'}
						download
					>
						{t('download_instruct')}
					</a>
				</>
			),
		},
	]

	const servicesArray = [
		{
			title: t('integration_unified_system'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('service_assignment')}
					</Title>
					<p>{t('the_service_is_intended_registration')}</p>
					<Title className={styles.pTop} level={3}>
						{t('link_service_smartbridge')}
					</Title>
					<a
						href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-0356"
						target="_blank"
						rel="noopener noreferrer"
						style={{ wordWrap: 'break-word' }}
					>
						https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-0356
					</a>
					<Title className={styles.pTop} level={3}>
						{t('service_description')}
					</Title>
					<a href="../../../files/info-service.rar" download>
						{t('download_archive_service')}
					</a>
				</>
			),
		},
		{
			title: t('integration_unified_system_recording'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('service_assignment')}
					</Title>
					<p>{t('the_service_is_designed_batch_loading')}</p>
					<Title className={styles.pTop} level={3}>
						{t('link_service_smartbridge')}
					</Title>
					<a
						href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3301"
						target="_blank"
						rel="noopener noreferrer"
						style={{ wordWrap: 'break-word' }}
					>
						https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3301
					</a>
					<Title className={styles.pTop} level={3}>
						{t('service_description')}
					</Title>
					<a href="../../../files/info-service-package.rar" download>
						{t('download_archive_service')}
					</a>
				</>
			),
		},
		{
			title: t('integration_unified_system_recording_fastening'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('service_assignment')}
					</Title>
					<p>{t('the_service_is_designed_to_synchronize')}</p>
					<Title className={styles.pTop} level={3}>
						{t('link_service_smartbridge')}
					</Title>
					<a
						href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3023"
						target="_blank"
						rel="noopener noreferrer"
						style={{ wordWrap: 'break-word' }}
					>
						https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3023
					</a>
					<Title className={styles.pTop} level={3}>
						{t('service_description')}
					</Title>
					<a href="../../../files/info-service-synch.rar" download>
						{t('download_archive_service')}
					</a>
				</>
			),
		},
		{
			title: t('worker_profile_details'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('service_assignment')}
					</Title>
					<p>{t('the_service_is_designed_to_send_employees_contracts')}</p>
					<Title className={styles.pTop} level={3}>
						{t('link_service_smartbridge')}
					</Title>
					<a
						href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3263"
						target="_blank"
						rel="noopener noreferrer"
						style={{ wordWrap: 'break-word' }}
					>
						https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-S-3263
					</a>
					<Title className={styles.pTop} level={3}>
						{t('service_description')}
					</Title>
					<a href="../../../files/info-service-employee.rar" download>
						{t('download_archive_service')}
					</a>
				</>
			),
		},
		{
			title: t('employee_personal_affairs'),
			desc: (
				<>
					<Title className={styles.pTop} level={3}>
						{t('service_assignment')}
					</Title>
					<p>{t('service_employer_package_employee')}</p>
					<Title className={styles.pTop} level={3}>
						{t('link_service_smartbridge')}
					</Title>
					<a
						href="https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-A-3318"
						target="_blank"
						rel="noopener noreferrer"
						style={{ wordWrap: 'break-word' }}
					>
						https://sb.egov.kz/smart-bridge/services/passport/MINTRUD-A-3318
					</a>
					<Title className={styles.pTop} level={3}>
						{t('service_description')}
					</Title>
					<a href="../../../files/info-service-affairs.rar" download>
						{t('download_archive_service')}
					</a>
				</>
			),
		},
	]

	const renderArray = useCallback((array) => {
		return (
			array?.map((item, index) => (
				<MyAccordion
					key={index}
					title={(
						<Typography>
							{item.title}
						</Typography>
					)}
					description={(
						<span className={styles.desc}>
											{item.desc}
										</span>
					)}
					isExpanded={activeAccordion === index}
					handleExpanded={() => handleExpandAccordion(index)}
				/>
			))
		)
	}, [activeAccordion, t, lang])

	const faqParent = useMemo(() => {
		return (faq.data || [])?.map(item => {
			const current = faqDict.data?.find(dict => dict.code == item.faqType)

			const titleByLang = {
				[LANG.rus]: current?.nameRu,
				[LANG.kz]: current?.nameKz,
			}
			return {
				title: titleByLang?.[lang],
				children: item.regQuestionDtos?.map(reg => {
					let title = {
						[LANG.rus]: reg.questionRu,
						[LANG.kz]: reg.questionKz,
					}

					let desc = {
						[LANG.rus]: reg.answerRu,
						[LANG.kz]: reg.answerKz,
					}
					return {
						title: title?.[lang],
						desc: desc?.[lang],
					}
				})
			}
		})
	}, [faq.data, lang, t, activeAccordion, faqDict.data])


	const [activeFaqItemIndex, setActiveFaqItemIndex] = useState(0)

	const agreementsRegData = useMemo(() => {
		return agreements.data?.regFileDtos?.map(i => {
			let res = districts?.find(reg => reg.code == i.cato)

			return {
				...i,
				rus: res?.rus,
				kz: res?.kz,
			}
		})
	}, [agreements.data, districts, t])

	const handleFaqClickItem = (index) => {
		setActiveFaqItemIndex(index)
		setActiveAccordion(null)
	}

	const handleAgreementClick = (item) => {
		dispatch(referenceInformationThunks.getDownloadByIdData(item))
	}

	const renderFaqContent = useMemo(() => {
		return (
			<div className={styles.faq}>
				{/*<Input prefix={<SearchIcon />} placeholder={t('enter_in')} />*/}

				{(faq.loading || faqDict.loading) ? <LoadingScreen /> :
					<div className={styles.faq__content}>
						<div className={styles.faq__parent}>
							<Title level={3}>
								{t('faq.choose_theme_question')}
							</Title>
							<div className={'mt-4'}>
								{faqParent.map((parent, parentIdx) => (
									<div key={parentIdx} onClick={() => handleFaqClickItem(parentIdx)} className={cl(styles.faq__button, {
										[styles.faq__buttonActive]: parentIdx === activeFaqItemIndex,
									})}>
										{parent.title}
									</div>
								))}
							</div>
						</div>

						<div className={cl(styles.faq__children, 'scroll')}>
							<Title level={3}>
								{faqParent?.[activeFaqItemIndex]?.title}
							</Title>

							<div className={'mt-4'}>
								{renderArray(faqParent?.[activeFaqItemIndex]?.children || [])}
							</div>
						</div>
					</div>
				}
			</div>
		)
	}, [activeFaqItemIndex, t, activeAccordion, faq, faqDict])

	const renderAgreementContent = useMemo(() => {
		return (
			<>
				{agreements.loading ? <LoadingScreen /> :
					<div className={cl('flex', 'flex-column', 'gap-4')}>
						<div className={cl('flex', 'flex-column', 'gap-2')}>
							<Title level={3}>
								{t('faq.gen_agreement')}
							</Title>

							<div className={cl('flex', 'flex-column', 'gap-2')}>
								{
									agreements.data?.genFileDto?.map((i, idx) => (
										<AgreementItem
											langKey={i.isKz ? AGREEMENT_KEY.nameKz : AGREEMENT_KEY.nameRu}
											key={idx}
											onClick={() => handleAgreementClick(i)}
											item={{
												nameRu: i.fileName,
												nameKz: i.fileName,
											}}
										/>
									))
								}
							</div>
						</div>


						<div className={cl('flex', 'flex-column', 'gap-2')}>
							<Title level={3}>
								{t('faq.reg_agreement')}
							</Title>

							<div className={cl('flex', 'flex-column', 'gap-4')}>
								{
									agreementsRegData?.map((outer, outerIdx) => {
										let current = {
											[LANG.rus]: outer.rus,
											[LANG.kz]: outer.kz,
										}
										return (
											<div key={outerIdx} className={cl('flex', 'gap-1', 'align-center', 'w-100')}>
												<span className={cl('w-25', 'flex')}>
													{current[language]}
												</span>

												<div className={cl('flex-1', 'flex', 'flex-column')}>
													{
														outer.flieDataDto?.map((i, idx) => (
															<AgreementItem
																langKey={i.isKz ? AGREEMENT_KEY.nameRu : AGREEMENT_KEY.nameKz}
																key={idx}
																onClick={() => handleAgreementClick(i)}
																item={{
																	nameRu: i.fileName,
																	nameKz: i.fileName,
																}}
															/>
														))
													}
												</div>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				}

			</>
		)
	}, [agreements, t, language])

	const tabs = [
		{
			label: t('helpTab'),
			render: renderArray(helpArray),
		},
		{
			label: t('instructions'),
			render: renderArray(instructionsArray),
		},
		{
			label: t('servicesTabs'),
			render: renderArray(servicesArray),
		},
		{
			label: t('asked_questions'),
			render: renderFaqContent,
		},
		{
			label: t('faq.agreement'),
			render: renderAgreementContent,
		},
	]

	const handleChangeTab = () => {
		setActiveAccordion(null)
	}

	const handleExpandAccordion = (index) => {
		setActiveAccordion(prevState => prevState === index ? null : index)
	}

	return (
		<>
			{/*раскомментировать в случае траура*/}
			{/*<PreHeader />*/}
			<>
				<Header active="reference_information" dark />
				<div className={`${styles.page} container`}>
					<Title level={1}>{t('reference_information')}</Title>
					<Tabs className="mt-64" onChange={handleChangeTab}>
						{tabs.map((tab, tabIdx) => (
							<TabPane className={styles.tabPane} tab={tab.label} key={tabIdx}>
								{tab.render}
							</TabPane>
						))}
					</Tabs>
				</div>
				<Footer />

				{download.loading && <LoadingBlocker />}
			</>
		</>
	)
}
