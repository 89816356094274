import { request } from '@/shared/api/request'

export const getEquipmentData = ({data, params, payload: {type}}) => {
	return request({ url: `/assessment/dict/${type}`, method: 'GET', data: data, params: params })
}

export const getDownloadData = (payload) => {
	return request({ url: '/assessment/dict/download', method: 'GET', params:  payload, responseType: 'blob'})
}

//todo delete
export const get = (payload) => {
	return request({ url: '/assessment/dict/equipment', method: 'GET', data: payload })
}

export const getAll = (params) => {
	return request({ url: '/assessment/dict/equipment', method: 'GET', params: params })
}
//todo delete

export const getDownload = () => {
	return request({ url: '/assessment/dict/download', method: 'GET', params: { dictName: 'equipment' } })
}

export const getDataByBin = (params) => request({ url: '/occupationalRisks/reorganize/check', method: 'GET', params })
