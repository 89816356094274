import cl from 'classnames'
import styles from './Modal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import React, { useEffect } from 'react'
import { FieldArray, Form, Formik, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button, Field } from '@/ui'
import plusIcon from '@/assets/icon/plus.svg'
import { EquipmentEditCreateSchema } from '@features/regulatoryInformation/Modal.schema'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'


const FORM_NAME = {
	dicts: 'dicts',

	name: 'name',
	commissioningDate: 'commissioningDate',
	serviceLife: 'serviceLife',
	isWithinServiceLife: 'isWithinServiceLife',
	isIndefinitely: 'isIndefinitely',

	status: 'status',
}


export const EquipmentEditModal = ({
																		 item: currentItem,
																		 onClose,
																		 onSubmit,
																	 }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { item } = currentItem

	const handleFormSubmit = (data) => {
		let body = {
			...data,
			[FORM_NAME.dicts]: data[FORM_NAME.dicts].map(i => ({
				...i,
				[FORM_NAME.status]: item ? 'edit' : 'create',
			})),
		}

		onSubmit(body)
	}

	const initialState = {
		[FORM_NAME.name]: item?.[FORM_NAME.name],
		[FORM_NAME.commissioningDate]: item?.[FORM_NAME.commissioningDate],
		[FORM_NAME.serviceLife]: item?.[FORM_NAME.serviceLife],
		[FORM_NAME.isIndefinitely]: item?.[FORM_NAME.isIndefinitely],
		[FORM_NAME.isWithinServiceLife]: item?.[FORM_NAME.isWithinServiceLife] || false,
	}

	const formInitialState = {
		dicts: [initialState],
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper)}>
			<ModalBackButton onClick={onClose} />
			<h4>
				{t('occupational_risks_assessment.regulatory_information.equipment_modal.title')}
			</h4>
			<div className={cl(styles.wrapper, 'scroll')}>
				<Formik
					initialValues={formInitialState}
					onSubmit={handleFormSubmit}
					enableReinitialize={true}
					validationSchema={EquipmentEditCreateSchema}
				>
					{({ values, errors }) => {
						return (
							<Form>
								<FieldArray name={FORM_NAME.dicts}>
									{({ remove, push }) => {
										return (
											<div className={cl('flex', 'flex-column', 'gap-5')}>
												{
													values?.dicts?.map((field, fieldIdx) => {
														return (
															<div key={fieldIdx} className={cl('flex', 'flex-column', 'gap-3')}>
																<div className={cl('flex', 'justify-content-between')}>
																	<h2>
																		{t('occupational_risks_assessment.regulatory_information.equipment')} {values?.dicts?.length >= 2 ? fieldIdx + 1 : ''}
																	</h2>
																	{
																		fieldIdx !== 0 && (
																			<Button
																				type="bare"
																				onClick={() => {
																					remove(fieldIdx)
																				}}>
																				{t('remove')}
																			</Button>
																		)
																	}
																</div>

																<div className={cl('flex', 'gap-3')}>
																	<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.name}`}>
																		{({ field, meta: { touched, error } }) => (
																			<Field
																				{...field}
																				label={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_1')}
																				placeholder={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_1')}
																				error={touched && error}
																			/>
																		)}
																	</FormikField>
																</div>

																<div className={cl('flex', 'gap-3')}>
																	<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.commissioningDate}`}>
																		{({ field, meta: { touched, error } }) => (
																			<Field
																				{...field}
																				fieldType="datePicker"
																				label={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_2')}
																				placeholder={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_2')}
																				error={touched && error}
																			/>
																		)}
																	</FormikField>
																	<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.serviceLife}`}>
																		{({ field, meta: { touched, error } }) => (
																			<Field
																				{...field}
																				type="number"
																				min={0}
																				hideNumberArrows
																				label={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_3')}
																				placeholder={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_3')}
																				error={touched && error}
																			/>
																		)}
																	</FormikField>
																	<div className={cl('flex')}>
																		<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.isIndefinitely}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					wrapperClassName={cl('flex', 'flex-row', 'align-items-center', 'mt-3')}
																					fieldType="checkbox"
																					firstField
																					label={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_4')}
																					error={touched && error}
																				/>
																			)}
																		</FormikField>
																	</div>
																</div>

																<div className={cl('flex', 'gap-3')}>
																	<FormikField
																		name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.isWithinServiceLife}`}>
																		{({ field, meta: { touched, error } }) => (
																			<Field
																				{...field}
																				fieldType="twoRadio"
																				isBoolean
																				firstId={1}
																				secondId={2}
																				label={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_5')}
																				placeholder={t('occupational_risks_assessment.regulatory_information.equipment_modal.field_5')}
																				error={touched && error}
																			/>
																		)}
																	</FormikField>
																</div>
															</div>
														)
													})
												}

												<div>
													<Button
														buttonClassName={cl('mt-4')}
														textColor="green"
														borderColor="green"
														transparent
														border
														icon={plusIcon}
														onClick={() => push(initialState)}>
														{t('occupational_risks_assessment.add_equip')}
													</Button>
												</div>
											</div>
										)
									}}
								</FieldArray>

								<div className={cl('flex', 'justify-content-end')}>
									<Button type="submit"
													disabled={!isEmpty(errors)}
									>
										{t('save')}
									</Button>
								</div>
							</Form>
						)
					}}
				</Formik>
			</div>
		</Modal>
	)
}