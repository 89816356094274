import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import { equipment1Thunks } from '@/entity'
import cl from 'classnames'
import styles from '@features/regulatoryInformation/Modal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Button, MultiSelect, Select } from '@/ui'
import { Table, TableColumn } from '@/components/Table'
import { Delete, Trash } from '@app/icons'
import { LoadingScreen } from '@/components/Loader'
import { getCurrentLanguage } from '@/i18next'

const TYPES = {
	equipmentsCodes: 'equipmentsCodes',
	ppmCodes: 'ppmCodes',
}

const LANG = {
	kz: 'kz',
	rus: 'rus',
}

export const EquipmentSelectModal = ({
																			 item: currentItem,
																			 onClose,
																			 onSubmit,
																			 type = TYPES.equipmentsCodes,
																		 }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()

	const [values, setValues] = useState([])

	const { equipmentSelect } = useSelector((state) => ({
		equipmentSelect: state.equipment1.equipmentSelect,
	}))

	useEffect(() => {
		let current = {
			[TYPES.equipmentsCodes]: 'equipment',
			[TYPES.ppmCodes]: 'personalProtectionMeans',
		}

		dispatch(equipment1Thunks.getEquipmentSelect(current[type]))

		let res = currentItem?.item?.child?.dicts?.[type]?.map(i => i.code)

		setValues(res || [])
	}, [])

	const handleChangeSelect = (codes) => {
		setValues(codes)
	}

	const handleDeleteSelect = (item) => {
		setValues(prevState => prevState?.filter(i => i !== item.code))
	}

	const columns = [
		{
			key: 'name',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_1'),
		},
		{
			key: 'commissioningDate',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_2'),
		},
		{
			key: 'serviceLife',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_3'),
		},
		{
			key: 'isWithinServiceLife',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_4'),
			render: (item, record) => {
				return item ? t('yes') : t('no')
			},
		},
		{
			title: '',
			key: 'actionRow',
			render: (item, record) => {
				return <div role="button" onClick={() => handleDeleteSelect(record)}><Delete /></div>
			},
		},
	]

	const data = useMemo(() => {
		return (values || []).map(item => {
			const res = equipmentSelect?.data?.content?.find(data => data?.code === item)

			let currentByLang = {
				[LANG.kz]: res?.nameKz,
				[LANG.rus]: res?.nameRu,
			}
			const name = {
				[TYPES.equipmentsCodes]: res?.name,
				[TYPES.ppmCodes]: currentByLang?.[lang],
			}

			return {
				code: res?.code,
				name: name?.[type],
				commissioningDate: res?.commissioningDate,
				serviceLife: res?.serviceLife,
				isWithinServiceLife: res?.isWithinServiceLife,
			}
		})
	}, [values, equipmentSelect?.data?.content, lang])


	const handleSubmitButton = () => {
		onSubmit(values)
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper)}>
			<ModalBackButton onClick={onClose} />
			<h4>
				{t('occupational_risks_assessment.regulatory_information.equipment_modal.title')}
			</h4>
			{equipmentSelect?.loading ? <LoadingScreen /> :
				<>
					<div className={cl(styles.wrapper, 'scroll')}>
						<div className={cl('flex', 'flex-column', 'gap-4')}>
							<MultiSelect
								value={values}
								onChange={handleChangeSelect}
								placeholder={t('select_from_list')}
								options={(equipmentSelect?.data?.content || [])?.map(item => {
									let currentByLang = {
										[LANG.kz]: item?.nameKz,
										[LANG.rus]: item?.nameRu,
									}
									const name = {
										[TYPES.equipmentsCodes]: item?.name,
										[TYPES.ppmCodes]: currentByLang?.[lang],
									}
									return {
										code: item.code,
										kz: name?.[type],
										rus: name?.[type],
									}
								})}
							/>

							<div>
								<Table
									tableName="table"
									data={data || []}
									emptyPlaceholder="-"
								>
									{(columns || []).map(item => (
										<TableColumn
											key={item.key}
											dataKey={item.key}
											heading={item.title}
											renderCell={(cell, record) => item.render ? item.render(cell, record) : cell}
										/>
									))}
								</Table>
							</div>
						</div>
					</div>

					<div className={cl('flex', 'justify-content-end', 'gap-4', 'mt-4')}>
						<Button type="bare" onClick={onClose}>
							{t('cancel')}
						</Button>

						<Button onClick={handleSubmitButton} disabled={!values.length}>
							{t('add')}
						</Button>
					</div>
				</>}
		</Modal>
	)
}