import { request } from '@/shared/api/request'

export const remove = (payload) => {
	return request({ url: 'occupationalRisks/sendDeletionData', method: 'DELETE', data: payload })
}

export const removeRequest = (id) => {
	return request({ url: `assessment/delete/${id}`, method: 'DELETE' })
}

export const removeOccupationalRisks = (data) => request({ method: 'DELETE', url: 'occupationalRisks/delete', data })
