import cl from 'classnames'
import styles from '@features/regulatoryInformation/Modal.module.scss'
import { Modal } from '@/components/Modal'
import { Icon } from '@/components/Icon'
import { InfoGreen } from '@app/icons'
import { useTranslation } from 'react-i18next'
import { Button } from '@/ui'


export const StaffAlertModal = ({
																	onClose,
																	onSubmit,
																	text,
																	submitLabel,
																}) => {
	const { t } = useTranslation()

	return (
		<Modal windowClassName={cl(styles.modal_wrapper, styles.modal_wrapper__small)}>
			<div className={cl('flex', 'flex-column', 'gap-4', 'align-center')}>
				<div>
					<Icon component={InfoGreen}/>
				</div>

				<span className={cl('text-center')} style={{fontSize: 18, fontWeight: 500, color: '#1D293F'}}>
					{text}
				</span>

				<Button onClick={onSubmit}>
					{submitLabel}
				</Button>

				<Button onClick={onClose} type="bare">
					{t('labor_records.cancel_text')}
				</Button>
			</div>
		</Modal>
	)
}