import { api } from '@/shared'
import { message } from 'antd'
import { protectiveEquipment1Actions } from '@/entity/regulatoryInformation/protectiveEquipment/index'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'

export const createEditEquipment = (payload) => {
	const {data, onSuccess} = payload
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipment1Actions.createEditEquipmentAction())

			const res = await api.regulatoryInformation.equipment.createEditEquipmentData(data)

			dispatch(protectiveEquipment1Actions.createEditEquipmentActionDone(data))
			onSuccess(res)

		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(protectiveEquipment1Actions.createEditEquipmentActionFail())
		}
	}
}

export const importEquipment = (payload) => {
	const {data, onSuccess} = payload
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipment1Actions.importEquipmentAction())

			const res = await api.regulatoryInformation.protectiveEquipment.sendFile(data)

			dispatch(protectiveEquipment1Actions.importEquipmentActionDone(data))
			onSuccess(res)

		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(protectiveEquipment1Actions.importEquipmentActionFail())
		}
	}
}

export const getEquipment = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipment1Actions.getEquipmentAction())

			let body = {
				data: payload.data,
				params: {
					srcText: payload.params.srcText,
					isDeleted: payload.params.isDeleted,
					page: payload.params.page,
					size: payload.params.size,
				},
				payload: payload.payload
			}

			const res = await api.regulatoryInformation.equipment.getEquipmentData(body)

			if(res){
				dispatch(protectiveEquipment1Actions.getEquipmentActionDone(res))
			}

		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(protectiveEquipment1Actions.getEquipmentActionFail())
		}
	}
}

export const getDownload = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipment1Actions.getDownloadAction())

			const res = await api.regulatoryInformation.equipment.getDownloadData(payload)

			if(res){
				dispatch(protectiveEquipment1Actions.getDownloadActionDone())

				const blob = new Blob([res], {
					type: 'application/vnd.ms-excel',
				});

				fileDownload(blob, `download_${dayjs().toString()}.xlsx`)
			}

		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(protectiveEquipment1Actions.getDownloadActionFail())
		}
	}
}