import { number, object, string } from 'yup'
import * as yup from 'yup'
import { FormingAgreementModal } from '@features/occupationalRisks'

export const EquipmentEditCreateSchema = object().shape({
	dicts: yup.array().of(
		yup.object().shape({
			name: yup.string().required({key: 'required'}),
			commissioningDate: yup.string().required({key: 'required'}),
			serviceLife: yup.string().when('isIndefinitely', {
				is: true,
				then: yup.string().notRequired(),
				otherwise: yup.string().required({key: 'required'}),
			}),
			isWithinServiceLife: yup.string().required({key: 'required'}),
		})
	),
})

export const PPMEquipmentEditCreateSchema = object().shape({
	dicts: yup.array().of(
		yup.object().shape({
			nameKz: yup.string().required({key: 'required'}),
			nameRu: yup.string().required({key: 'required'}),
			issueRateInMonths: yup.string().required({key: 'required'}),
			normativDocument: yup.string().required({key: 'required'}),
			isCert: yup.string().required({key: 'required'}),
			isCompliantWithStandards: yup.string().required({key: 'required'}),
		})
	),
})

export const Step3Schema = object().shape({
	plannedDays: number().required({key: 'required'}).nullable(true),
	totalServiceCost: number().required({key: 'required'}).nullable(true),
})

export const FormingAgreementModalSchema = object().shape({
	signingReasonRu: string().required({key: 'required'}).nullable(true),
	signingReasonKz: string().required({key: 'required'}).nullable(true),
	directorFullName: string().required({key: 'required'}).nullable(true),
	customerPhone: string().required({key: 'required'}).nullable(true),
	bik: string().required({key: 'required'}).nullable(true),
	iik: string().required({key: 'required'}).nullable(true),
	kbe: string().required({key: 'required'}).nullable(true),
	bankNameRu: string().required({key: 'required'}).nullable(true),
	bankNameKz: string().required({key: 'required'}).nullable(true),
})