import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import cl from 'classnames'
import React, { useMemo, useState } from 'react'
import { ElectronicContractModal, FormingAgreementModal } from '@features/occupationalRisks'
import Button from '@/components/Button'
import { equipmentContractThunks } from '@/entity/regulatoryInformation/contract'
import { DigitalSign } from '@/utils/digitalSign'
import { message } from 'antd'
import { useParams } from 'react-router-dom'
import doc from '@/assets/home/doc.svg'
import trash from '@/assets/home/trash.svg'
import { LoadingBlocker } from '@/components/Loader'
import styles from './styles.module.scss'
import { Table, TableColumn } from '@/components/Table'
import { getCurrentLanguage } from '@/i18next'

const initialState = {
	item: null,
	action: null,
}

const LANG = {
	rus: 'rus',
	kz: 'kz',
}

const ACTIONS = {
	forming_agreement: 'forming_agreement',
	electronic_contract: 'electronic_contract',
}

const STATUSES = {
	SELECTED_SO: 'SELECTED_SO',
	AT_SIGNING: 'AT_SIGNING',
	SIGNED: 'SIGNED',
}

export const Step5 = ({
												onSuccessStep,
											}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const params = useParams()
	const lang = getCurrentLanguage()

	const digitalSign = new DigitalSign()

	const { type, id } = params

	const { equipment, document, contract, xml } = useSelector((state) => ({
		equipment: state.equipment1.oneEquipment,
		document: state.equipmentContract?.document,
		contract: state.equipmentContract?.createOrUpdate,
		xml: state.equipmentContract?.xml
	}))

	const [modalData, setModalData] = useState(initialState)

	const handleModalClose = () => {
		setModalData(initialState)
	}

	const onSubmit = (data) => {
		let body = {
			data: {
				applicationExternalId: equipment?.data?.externalId,
				...data
			},
			onSuccess: () => {
				message.success('Успешно!')
				handleModalClose()
				onSuccessStep()
			}
		}

		dispatch(equipmentContractThunks.setCreateUpdate(body))
	}

	const handleFormingAgreement = () => {
		setModalData(prevState => ({
			...prevState,
			action: ACTIONS.forming_agreement
		}))
	}

	const handleSetXml = () => {
		let xmlBody = {
			data: {
				contractExternalId: equipment?.data?.contractStatus?.externalId
			},
			onSuccess: async ({xml}) => {
				const res = await digitalSign.handleSend(xml)
				let xmlSendBody = {
					data: {
						data: res,
						externalId: equipment?.data?.contractStatus?.externalId
					},
					onSuccess: (xmlSendRes) => {
						message.success('Успешно!')
						handleModalClose()
						onSuccessStep()
					}
				}

				dispatch(equipmentContractThunks.setXmlSend(xmlSendBody))
			}
		}
		dispatch(equipmentContractThunks.setXml(xmlBody))
	}

	const handleViewDoc = () => {
		let body = {
			data: equipment?.data?.contractStatus?.fileId,
			onSuccess: (res) => {
				setModalData(prevState => ({
					...prevState,
					action: ACTIONS.electronic_contract
				}))
			},
		}
		dispatch(equipmentContractThunks.getDownloadFile(body))
	}

	const handleRemoveDoc = () => {
		let body = {
			data: equipment?.data?.contractStatus?.externalId,
			onSuccess: () => {
				message.success('Успешно!')
				onSuccessStep()
			}
		}
		dispatch(equipmentContractThunks.deleteData(body))
	}

	const columns = [
		{
			title: t('electronic_contract.role'),
			key: 'role',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('account_details'),
			key: 'details',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('enterprise_name'),
			key: 'name',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('personal_affairs.sign_date'),
			key: 'sign_date',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('contracts_tab.status'),
			key: 'status',
			render: (item, record) => {
				return item
			},
		},
	]

	const tableRender = useMemo(() => {
		const status = {
			DOC_CUSTOMER_SIGNED: t('signed_status'),
			DOC_EXECUTOR_SIGNED: t('signed_status'),
			DOC_SIGN_WAITING: t('pending_sign'),
			DOC_EXECUTOR_SIGN_WAIT: t('pending_sign'),
		}

		const name = {
			[LANG.kz]: equipment?.data?.orgNameKz,
			[LANG.rus]: equipment?.data?.orgName,
		}

		const soName = {
			[LANG.kz]: equipment?.data?.protocol?.soNameKz,
			[LANG.rus]: equipment?.data?.protocol?.soNameRu,
		}

		const data = [
			{
				role: t('employer'),
				details: equipment?.data?.contractStatus?.requisite || '-',
				name: name?.[lang] || '-',
				sign_date: equipment?.data?.contractStatus?.signDate,
				status: (
					<span className={cl(styles[`step5status__` + equipment?.data?.contractStatus?.firstPartySignStatus])}>{status?.[equipment?.data?.contractStatus?.firstPartySignStatus]}</span>
				),
			},
			{
				role: t('occupational_risks_assessment.steps.step_5.organization'),
				details: equipment?.data?.contractStatus?.soRequisite || '-',
				name: soName?.[lang] || '-',
				sign_date: equipment?.data?.contractStatus?.soSignDate || '-',
				status: (
					<span className={cl(styles[`step5status__` + equipment?.data?.contractStatus?.secondPartySignStatus])}>{status?.[equipment?.data?.contractStatus?.secondPartySignStatus]}</span>
				),
			}
		]

		return (
			<div>
				<Table
					tableName="table"
					data={data || []}
					emptyPlaceholder="-"
					tableClassName={styles.table}
					wrapperClassName={styles.tableWrapper}
				>
					{(columns || []).map(item => (
						<TableColumn
							key={item.key}
							dataKey={item.key}
							heading={item.title}
							renderCell={(cell, record) => item.render ? item.render(cell, record) : cell}
						/>
					))}
				</Table>
			</div>
		)
	}, [equipment?.data, t, lang])

	const render = useMemo(() => {
		return {
			[STATUSES.SIGNED]: tableRender,
			[STATUSES.AT_SIGNING]: tableRender,
			[STATUSES.SELECTED_SO]: (
				<div>
					<Button onClick={handleSetXml}>
						{t('actions.sign')}
					</Button>
				</div>
			),
		}
	}, [equipment?.data?.status])

	return (
		<>
			{(document.loading || contract?.loading || xml?.loading) && <LoadingBlocker />}

			{modalData.action === ACTIONS.forming_agreement &&
				<FormingAgreementModal item={modalData.item} onClose={handleModalClose} onSubmit={onSubmit} />}
			{modalData.action === ACTIONS.electronic_contract &&
				<ElectronicContractModal item={modalData.item} onClose={handleModalClose} onSubmit={onSubmit} />}
			<div className={cl('py-4')}>
				{
					equipment?.data?.contractStatus?.externalId ?
						(
							<div className={cl('flex', 'flex-column', 'gap-4')}>
								<div className={cl('flex', 'align-center', 'gap-2')}>
									<img src={doc} />
									<label>{equipment?.data?.contractStatus?.fileName}</label>
									<span onClick={handleViewDoc} role="button" className={styles.download}>{t('actions.browsing')}</span>
									{
										equipment?.data?.status === STATUSES.SELECTED_SO &&
										<div className={cl('flex', 'align-center', 'gap-1')}>
											<img src={trash} />
											<span onClick={handleRemoveDoc} role="button" className={styles.remove}>{t('remove')}</span>
										</div>
									}
								</div>

								<div className={cl('flex', 'flex-column', 'gap-4')}>
									{render?.[equipment?.data?.status] || ''}
								</div>
							</div>
						)
						:
						(
							<Button onClick={handleFormingAgreement}>
								{t('occupational_risks_assessment.steps.step_5.create')}
							</Button>
						)
				}


			</div>
		</>
	)
}