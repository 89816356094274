import { equipment1Actions } from '@/entity/regulatoryInformation/equipment/index'
import { api } from '@/shared'
import { message } from 'antd'

export const getEquipment = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.getEquipmentDataAction())

			let params = {
				srcText: payload.srcText,
				isDeleted: payload.isDeleted,
				page: payload.page,
				size: payload.size,
			}

			const res = await api.occupationalRisks.getAll(params)

			if(res){
				dispatch(equipment1Actions.getEquipmentDataActionDone(res))
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.getEquipmentDataActionFail())
		}
	}
}


export const getOneEquipment = (payload) => {
	const {data, onSuccess, onError} = payload
	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.getOneEquipmentAction())

			let res

			if(data.payload.type === 'create'){
				res = await api.occupationalRisks.createOccupationalRisk(data.data)
			} else if(data.payload.type === 'view') {
				res = await api.occupationalRisks.getOccupationalRisk(data.payload?.externalId)
			} else {
				dispatch(equipment1Actions.getOneEquipmentActionFail())
			}

			dispatch(equipment1Actions.getOneEquipmentActionDone(res))
		} catch (error) {
			message.error(error.response?.data?.message)
			onError()

			dispatch(equipment1Actions.getOneEquipmentActionFail())
		}
	}
}

export const getBranches = (payload) => {
	const {onSuccess} = payload
	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.getBranchesAction())

			const res = await api.occupationalRisks.getBranches()

			if(res){
				let array = res?.map((i, idx) => ({...i, key: idx + 1}))

				dispatch(equipment1Actions.getBranchesActionDone(array))

				if(array.length === 1){
					onSuccess(array)
				}
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.getBranchesActionFail())
		}
	}
}


export const setEquipment = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.setEquipmentAction())

			const res = await api.occupationalRisks.createOccupationalRisk(data)

			if(res){
				dispatch(equipment1Actions.setEquipmentActionDone(res))
				onSuccess(res)
			}
		} catch (error) {
			message.error(error.response?.data?.message)
			onError()
			dispatch(equipment1Actions.setEquipmentActionFail())
		}
	}
}


export const getHarmful = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.getHarmfulAction())

			const res = await api.occupationalRisks.getHarmful()

			if(res){
				dispatch(equipment1Actions.getHarmfulActionDone(res))
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.getHarmfulActionFail())
		}
	}
}

export const getStaff = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.getStaffAction())

			const res = await api.occupationalRisks.getStaff(data)

			if(res){
				dispatch(equipment1Actions.getStaffActionDone(res))
				onSuccess(res)
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.getStaffActionFail())
		}
	}
}


export const getEquipmentSelect = (payload) => {
	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.getEquipmentSelectAction())

			const res = await api.regulatoryInformation.protectiveEquipment.getData(payload)

			if(res){
				dispatch(equipment1Actions.getEquipmentSelectActionDone(res))
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.getEquipmentSelectActionFail())
		}
	}
}

export const getXml = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.getXmlAction())

			const res = await api.regulatoryInformation.equipment.postXml(data)

			if(res){
				dispatch(equipment1Actions.getXmlActionDone(res))
				onSuccess(res)
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.getXmlActionFail())
		}
	}
}


export const postXmlSend = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.postXmlAction())

			const res = await api.regulatoryInformation.equipment.postXmlSend(data)

			dispatch(equipment1Actions.postXmlActionDone(res))
			onSuccess()
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.postXmlActionFail())
		}
	}
}


export const deleteRequest = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.deleteRequestAction())

			const res = await api.regulatoryInformation.equipment.removeRequest(data)

			dispatch(equipment1Actions.deleteRequestActionDone(res))
			onSuccess()
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.deleteRequestActionFail())
		}
	}
}


export const setBranchConsent = (payload) => {
	const {data, onSuccess} = payload

	return async (dispatch) => {
		try {
			dispatch(equipment1Actions.setBranchConsentAction())

			const res = await api.regulatoryInformation.equipment.staffBranchConsent(data)

			dispatch(equipment1Actions.setBranchConsentActionDone(res))
			onSuccess(res)
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipment1Actions.setBranchConsentActionFail())
		}
	}
}