import cl from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { equipment1Thunks } from '@/entity'
import { Button } from '@/ui'
import { Plus, RefreshGreen } from '@app/images'
import { ExpandedTable } from '@/components/ExpandedTable'
import { HarmfulModal, StaffAlertModal } from '@features/occupationalRisks'
import { message } from 'antd'
import { EquipmentSelectModal } from '@features/regulatoryInformation'
import { getCurrentLanguage } from '@/i18next'
import styles from './styles.module.scss'
import { Table, TableColumn } from '@/components/Table'

const MODAL_TYPES = {
	HAS_NOT_STAFF: 'HAS_NOT_STAFF',
	HAS_STAFF: 'HAS_STAFF',
}

const DICTS = {
	equipmentsCodes: 'equipmentsCodes',
	harmfulCodes: 'harmfulCodes',
	ppmCodes: 'ppmCodes',
}

const initialState = {
	item: null,
	action: null,
}

const LANG = {
	rus: 'rus',
	kz: 'kz',
}

export const Step2 = ({
												onSuccessStep,
											}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const params = useParams()
	const lang = getCurrentLanguage()

	const { type, id } = params

	const { equipment, harmful, company } = useSelector((state) => ({
		equipment: state.equipment1.oneEquipment,
		harmful: state.equipment1.harmful,
		company: state?.company?.company,
	}))

	useEffect(() => {
		dispatch(equipment1Thunks.getHarmful())
	}, [])

	const [modalData, setModalData] = useState(initialState)

	const refreshStaffButton = () => {
		let findObject = equipment?.data?.orgs?.find(i => i.bin === company.bin)
		let condition = findObject?.staus === 'APPROVED'

		setModalData(prevState => ({
			...prevState,
			action: condition ? MODAL_TYPES.HAS_STAFF : MODAL_TYPES.HAS_NOT_STAFF,
		}))
	}

	const columns = useMemo(() => ([
		{
			key: 'number',
			title: t('occupational_risks_assessment.steps.step_2.step_2_table.field_1'),
			dataIndex: 'number',
			render: (item, record, index) => {
				return index + 1
			},
		},
		{
			key: 'establishedPostName',
			title: t('occupational_risks_assessment.steps.step_2.step_2_table.field_2'),
			dataIndex: 'establishedPostName',
			render: (item, record, index) => {
				return record.establishedPostNameRu
			},
		},
		{
			key: 'parentDeptCode',
			title: t('occupational_risks_assessment.steps.step_2.step_2_table.field_3'),
			dataIndex: 'parentDeptCode',
			render: (item, record, index) => {
				return record.parentDeptCode
			},
		},
		{
			key: 'category',
			title: t('occupational_risks_assessment.steps.step_2.step_2_table.field_4'),
			dataIndex: 'category',
			render: (item, record, index) => {
				return record.category
			},
		},
		{
			key: 'count',
			title: t('occupational_risks_assessment.steps.step_2.step_2_table.field_5'),
			dataIndex: 'count',
			render: (item, record, index) => {
				return record.count
			},
		},
	]), [t])

	const columnsEquipment = useMemo(() => ([
		{
			key: 'name',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_1'),
		},
		{
			key: 'commissioningDate',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_2'),
		},
		{
			key: 'serviceLife',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_3'),
		},
		{
			key: 'isWithinServiceLife',
			title: t('occupational_risks_assessment.steps.step_2.eq_table.field_4'),
			render: (item, record) => {
				return item ? t('yes') : t('no')
			},
		},
	]), [t])

	const columnsPPEquipment = useMemo(() => ([
		{
			key: 'name',
			title: t('occupational_risks_assessment.steps.step_2.protective_table.field_1'),
		},
		{
			key: 'issueRateInMonths',
			title: t('occupational_risks_assessment.steps.step_2.protective_table.field_2'),
		},
		{
			key: 'isCompliantWithStandards',
			title: t('occupational_risks_assessment.steps.step_2.protective_table.field_3'),
			render: (item, record) => {
				return item ? t('yes') : t('no')
			},
		},
		{
			key: 'isCert',
			title: t('occupational_risks_assessment.steps.step_2.protective_table.field_4'),
			render: (item, record) => {
				return item ? t('yes') : t('no')
			},
		},
	]), [t])

	const contentRender = useMemo(() => ([
		{
			key: DICTS.harmfulCodes,
			label: t('occupational_risks_assessment.steps.step_2.harmful'),
			render: (item) => {
				return (
					<div className={cl('flex', 'gap-2', {
						'mt-4': item.dicts.harmfulCodes?.length,
					})}>
						{item?.dicts?.harmfulCodes?.map(code => {
							const currentLang = {
								[LANG.rus]: code?.nameKz,
								[LANG.kz]: code?.nameRu,
							}

							return (
								<span key={code?.code} className={cl(styles.badge)}>{currentLang[lang]}</span>
							)
						})}
					</div>
				)
			},
		},
		{
			key: DICTS.equipmentsCodes,
			label: t('occupational_risks_assessment.steps.step_2.equipments'),
			render: (item) => {
				return (
					item.dicts?.equipmentsCodes?.length ? (
						<div className={cl({
							'mt-4': item?.dicts?.equipmentsCodes?.length,
						})}>
							<Table
								tableName="table"
								data={item.dicts?.equipmentsCodes || []}
								emptyPlaceholder="-"
							>
								{(columnsEquipment || []).map(item => (
									<TableColumn
										key={item?.key}
										dataKey={item?.key}
										heading={item?.title}
										renderCell={(cell, record) => item.render ? item.render(cell, record) : cell}
									/>
								))}
							</Table>
						</div>
					) : null
				)
			},
		},
		{
			key: DICTS.ppmCodes,
			label: t('occupational_risks_assessment.steps.step_2.protective_equipment'),
			render: (item) => {
				return (
					item.dicts?.ppmCodes?.length ? (
						<div className={cl({
							'mt-4': item?.dicts?.ppmCodes?.length,
						})}>
							<Table
								tableName="table"
								data={item?.dicts?.ppmCodes?.map(ppm => {
									const currentLang = {
										[LANG.kz]: ppm?.nameKz,
										[LANG.rus]: ppm?.nameRu,
									}

									return {
										...ppm,
										name: currentLang?.[lang],
									}
								}) || []}
								emptyPlaceholder="-"
							>
								{(columnsPPEquipment || []).map(item => (
									<TableColumn
										key={item?.key}
										dataKey={item?.key}
										heading={item?.title}
										renderCell={(cell, record) => item?.render ? item?.render(cell, record) : cell}
									/>
								))}
							</Table>
						</div>
					) : null
				)
			},
		},
	]), [t, lang, harmful])

	const handleAddButton = (item, key) => {
		setModalData(prevState => ({
			...prevState,
			item: item,
			action: key,
		}))
	}

	const getData = useCallback((object) => {
		const array = object.staffs || []

		return array.map(item => {
			return ({
				...item,
				render: (
					<div className={cl('flex', 'flex-column', 'gap-4')}>
						{contentRender.map(content => (
							<div key={content.key}>
								<div className={cl('flex', 'align-center', 'justify-content-between')}>
									<span>
										{content.label}
									</span>
									{
										equipment?.data?.status === 'PROJECT' &&
										<div role="button" onClick={() => handleAddButton({ parent: object, child: item }, content.key)}>
											<img src={Plus} className={'me-2'} />
											{t('add')}
										</div>
									}
								</div>

								<div>
									{content.render(item)}
								</div>
							</div>
						))}
					</div>
				),
			})
		})
	}, [t, lang, harmful])

	const handleModalClose = () => {
		setModalData(initialState)
	}

	const onSubmit = (data) => {
		const { staus: parentStaus, staffs, ...parentObj } = modalData.item.parent
		const { ...childObj } = modalData.item.child

		let res = {
			externalId: equipment.data?.externalId,
			orgs: equipment.data?.orgs?.map(root => {
				if (root.externalId === parentObj.externalId) {
					return {
						...parentObj,
						professionGroupsPayload: modalData.item.parent?.staffs?.map(inner => {
							if (inner.professionGroupExternalId === childObj.professionGroupExternalId) {
								return {
									professionGroupExternalId: inner.professionGroupExternalId,
									dictsPayloads: {
										...inner.dicts,
										[DICTS.harmfulCodes]: inner.dicts?.harmfulCodes?.map(i => i.code),
										[DICTS.equipmentsCodes]: inner.dicts?.equipmentsCodes?.map(i => i.code),
										[DICTS.ppmCodes]: inner.dicts?.ppmCodes?.map(i => i.code),
										[modalData.action]: data,
									},
								}
							} else {
								return {
									professionGroupExternalId: inner.professionGroupExternalId,
									dictsPayloads: {
										...inner.dicts,
										[DICTS.harmfulCodes]: inner.dicts?.harmfulCodes?.map(i => i.code),
										[DICTS.equipmentsCodes]: inner.dicts?.equipmentsCodes?.map(i => i.code),
										[DICTS.ppmCodes]: inner.dicts?.ppmCodes?.map(i => i.code),
									},
								}
							}
						}),
					}
				} else {
					return {
						...parentObj,
						professionGroupsPayload: modalData.item.parent?.staffs?.map(inner => {
							return {
								professionGroupExternalId: inner.professionGroupExternalId,
								dictsPayloads: {
									...inner.dicts,
									[DICTS.harmfulCodes]: inner.dicts?.harmfulCodes?.map(i => i.code),
									[DICTS.equipmentsCodes]: inner.dicts?.equipmentsCodes?.map(i => i.code),
									[DICTS.ppmCodes]: inner.dicts?.ppmCodes?.map(i => i.code),
								},
							}
						}),
					}
				}
			}),
		}

		let body = {
			data: res,
			onSuccess: (res) => {
				handleModalClose()
				message.success('Успешно!')
			},
			onError: () => {
				handleModalClose()
			},
		}

		dispatch(equipment1Thunks.setEquipment(body))
	}

	const onSubmitStaff = () => {
		if(modalData.action === MODAL_TYPES.HAS_STAFF){
			let body = {
				data: {
					bin: equipment.data?.bin,
					applicationExternalId: equipment.data?.externalId,
				},
				onSuccess: () => {
					message.success('Успешно!')
					handleModalClose()

					onSuccessStep()
				}
			}
			dispatch(equipment1Thunks.getStaff(body))
		} else {
			history.push('/staffing')
		}
	}

	return (
		<>
			{modalData.action === DICTS.harmfulCodes &&
				<HarmfulModal item={modalData.item} onClose={handleModalClose} onSubmit={onSubmit} />}
			{modalData.action === DICTS.equipmentsCodes &&
				<EquipmentSelectModal item={modalData} onClose={handleModalClose} onSubmit={onSubmit}
															type={DICTS.equipmentsCodes} />}
			{modalData.action === DICTS.ppmCodes &&
				<EquipmentSelectModal item={modalData} onClose={handleModalClose} onSubmit={onSubmit} type={DICTS.ppmCodes} />}
			{modalData.action === MODAL_TYPES.HAS_NOT_STAFF && <StaffAlertModal onClose={handleModalClose} onSubmit={onSubmitStaff} text={t('occupational_risks_assessment.steps.step_2.info_not_staff')} submitLabel={t('notification_open_my_unions')}/>}
			{modalData.action === MODAL_TYPES.HAS_STAFF && <StaffAlertModal onClose={handleModalClose} onSubmit={onSubmitStaff} text={t('occupational_risks_assessment.steps.step_2.info_staff')} submitLabel={t('update')}/>}

			<div className={cl('py-4')}>
				<div>
					{
						equipment?.data?.status === 'PROJECT' &&
						<div className={cl('mb-4', 'w-100')}>
							<Button
								textColor="green"
								borderColor="green"
								transparent
								border
								icon={RefreshGreen}
								onClick={refreshStaffButton}
							>
								{t('occupational_risks_assessment.steps.step_2.update_schedule')}
							</Button>
						</div>
					}


					{(equipment.data?.orgs || [])?.map((item, index) => {
						const currentLang = {
							[LANG.rus]: item.orgNameRu,
							[LANG.kz]: item.orgNameKz,
						}
						return (
							<div key={index} className={cl('mb-4', 'flex-1')}>
								<h5 className={cl('mb-4')}>
									{currentLang?.[lang]}
								</h5>

								<div>
									<ExpandedTable
										pagination={false}
										columns={columns}
										dataSource={getData(item)}
										expandable={{
											expandedRowRender: (record) => record.render,
										}}
										locale={{
											emptyText: t('occupational_risks_assessment.steps.step_1.waiting')
										}}
									/>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}