import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null
}

const initialState = {
	createEdit: data,
	import: data,
	equipment: data,
	download: data,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.CREATE_EDIT_EQUIPMENT:
			return { ...state, createEdit: {...data, loading: true} }

		case types.CREATE_EDIT_EQUIPMENT_DONE:
			return { ...state, createEdit: {...data, success: true, data: payload} }

		case types.CREATE_EDIT_EQUIPMENT_FAIL:
			return { ...state, createEdit: {...data, failed: true} }


		case types.IMPORT_EQUIPMENT:
			return { ...state, import: {...data, loading: true} }

		case types.IMPORT_EQUIPMENT_DONE:
			return { ...state, import: {...data, success: true, data: payload} }

		case types.IMPORT_EQUIPMENT_FAIL:
			return { ...state, import: {...data, failed: true} }



		case types.GET_EQUIPMENT:
			return { ...state, equipment: {...data, loading: true} }

		case types.GET_EQUIPMENT_DONE:
			return { ...state, equipment: {...data, success: true, data: payload} }

		case types.GET_EQUIPMENT_FAIL:
			return { ...state, equipment: {...data, failed: true} }



		case types.GET_DOWNLOAD:
			return { ...state, download: {...data, loading: true} }

		case types.GET_DOWNLOAD_DONE:
			return { ...state, download: {...data, success: true, data: payload} }

		case types.GET_DOWNLOAD_FAIL:
			return { ...state, download: {...data, failed: true} }

		default:
			return state
	}
}
