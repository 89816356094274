import * as types from './types'

export const actions = {
	getEquipmentDataAction: (payload) => ({
		type: types.GET_EQUIPMENT_DATA,
	}),

	getEquipmentDataActionDone: (payload) => ({
		type: types.GET_EQUIPMENT_DATA_DONE,
		payload,
	}),

	getEquipmentDataActionFail: (payload) => ({
		type: types.GET_EQUIPMENT_DATA_FAIL,
	}),


	getOneEquipmentAction: (payload) => ({
		type: types.GET_ONE_EQUIPMENT,
	}),

	getOneEquipmentActionDone: (payload) => ({
		type: types.GET_ONE_EQUIPMENT_DONE,
		payload,
	}),

	getOneEquipmentActionFail: (payload) => ({
		type: types.GET_ONE_EQUIPMENT_FAIL,
	}),


	getBranchesAction: (payload) => ({
		type: types.GET_BRANCHES,
	}),

	getBranchesActionDone: (payload) => ({
		type: types.GET_BRANCHES_DONE,
		payload,
	}),

	getBranchesActionFail: (payload) => ({
		type: types.GET_BRANCHES_FAIL,
	}),


	setEquipmentAction: (payload) => ({
		type: types.SET_EQUIPMENT,
	}),

	setEquipmentActionDone: (payload) => ({
		type: types.SET_EQUIPMENT_DONE,
		payload,
	}),

	setEquipmentActionFail: (payload) => ({
		type: types.SET_EQUIPMENT_FAIL,
	}),


	getHarmfulAction: (payload) => ({
		type: types.GET_HARMFUL,
	}),

	getHarmfulActionDone: (payload) => ({
		type: types.GET_HARMFUL_DONE,
		payload,
	}),

	getHarmfulActionFail: (payload) => ({
		type: types.GET_HARMFUL_FAIL,
	}),


	getEquipmentSelectAction: (payload) => ({
		type: types.GET_EQUIPMENT_SELECT,
	}),

	getEquipmentSelectActionDone: (payload) => ({
		type: types.GET_EQUIPMENT_SELECT_DONE,
		payload,
	}),

	getEquipmentSelectActionFail: (payload) => ({
		type: types.GET_EQUIPMENT_SELECT_FAIL,
	}),


	getXmlAction: (payload) => ({
		type: types.GET_XML,
	}),

	getXmlActionDone: (payload) => ({
		type: types.GET_XML_DONE,
		payload,
	}),

	getXmlActionFail: (payload) => ({
		type: types.GET_XML_FAIL,
	}),


	postXmlAction: (payload) => ({
		type: types.POST_XML,
	}),

	postXmlActionDone: (payload) => ({
		type: types.POST_XML_DONE,
		payload,
	}),

	postXmlActionFail: (payload) => ({
		type: types.POST_XML_FAIL,
	}),



	getStaffAction: (payload) => ({
		type: types.GET_STAFF,
	}),

	getStaffActionDone: (payload) => ({
		type: types.GET_STAFF_DONE,
		payload,
	}),

	getStaffActionFail: (payload) => ({
		type: types.GET_STAFF_FAIL,
	}),


	deleteRequestAction: (payload) => ({
		type: types.DELETE_REQUEST,
	}),

	deleteRequestActionDone: (payload) => ({
		type: types.DELETE_REQUEST_DONE,
		payload,
	}),

	deleteRequestActionFail: (payload) => ({
		type: types.DELETE_REQUEST_FAIL,
	}),


	setBranchConsentAction: (payload) => ({
		type: types.SET_BRANCH_CONSENT,
	}),

	setBranchConsentActionDone: (payload) => ({
		type: types.SET_BRANCH_CONSENT_DONE,
		payload,
	}),

	setBranchConsentActionFail: (payload) => ({
		type: types.SET_BRANCH_CONSENT_FAIL,
	}),
}
