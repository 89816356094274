import { Arrow } from '@app/icons'
import cl from 'classnames'
import { useState } from 'react'
import { StyledTable } from './/ExpandedTable.styled'
import styles from './ExpandedTable.module.scss'

export const ExpandedTable = (props) => {
	const [expanded, setExpanded] = useState([])

	return (
		<StyledTable
			{...props}
			dataSource={props?.dataSource?.map((i, idx) => ({...i, key: idx}))}
			expandIcon={({ expandable, expanded, onExpand, record }) => {
				return expandable && (
					<Arrow className={cl(styles.icon, { [styles.icon__expanded]: expanded })}
								 onClick={e => onExpand(record, e)} />
				)
			}}

			scroll={{x: true}}
			expandable={{
				expandedRowKeys: expanded,
				...props.expandable
			}}
			locale={{
				emptyText: props.locale.emptyText || 'Пусто'
			}}
			onRow={(record, rowIndex) => {
				return {
					onClick: (event) => {
						let find = expanded.includes(record.key)
							? expanded?.filter((key) => key !== record.key)
							: [...expanded, record.key]

						setExpanded(find)
					},
				}
			}}
		/>
	)
}