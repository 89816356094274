export const GET_EQUIPMENT_DATA = 'equipment/GET_EQUIPMENT_DATA'
export const GET_EQUIPMENT_DATA_DONE = 'equipment/GET_EQUIPMENT_DATA_DONE'
export const GET_EQUIPMENT_DATA_FAIL = 'equipment/GET_EQUIPMENT_DATA_FAIL'

export const GET_ONE_EQUIPMENT = 'equipment/GET_ONE_EQUIPMENT_'
export const GET_ONE_EQUIPMENT_DONE = 'equipment/GET_ONE_EQUIPMENT__DONE'
export const GET_ONE_EQUIPMENT_FAIL = 'equipment/GET_ONE_EQUIPMENT__FAIL'

export const GET_BRANCHES = 'equipment/GET_BRANCHES'
export const GET_BRANCHES_DONE = 'equipment/GET_BRANCHES_DONE'
export const GET_BRANCHES_FAIL = 'equipment/GET_BRANCHES_FAIL'

export const SET_EQUIPMENT = 'equipment/SET_EQUIPMENT'
export const SET_EQUIPMENT_DONE = 'equipment/SET_EQUIPMENT_DONE'
export const SET_EQUIPMENT_FAIL = 'equipment/SET_EQUIPMENT_FAIL'

export const GET_HARMFUL = 'equipment/GET_HARMFUL'
export const GET_HARMFUL_DONE = 'equipment/GET_HARMFUL_DONE'
export const GET_HARMFUL_FAIL = 'equipment/GET_HARMFUL_FAIL'

export const GET_EQUIPMENT_SELECT = 'equipment/GET_EQUIPMENT_SELECT'
export const GET_EQUIPMENT_SELECT_DONE = 'equipment/GET_EQUIPMENT_SELECT_DONE'
export const GET_EQUIPMENT_SELECT_FAIL = 'equipment/GET_EQUIPMENT_SELECT_FAIL'

export const GET_XML = 'equipment/GET_XML'
export const GET_XML_DONE = 'equipment/GET_XML_DONE'
export const GET_XML_FAIL = 'equipment/GET_XML_FAIL'

export const POST_XML = 'equipment/POST_XML'
export const POST_XML_DONE = 'equipment/POST_XML_DONE'
export const POST_XML_FAIL = 'equipment/POST_XML_FAIL'

export const GET_STAFF = 'equipment/GET_STAFF'
export const GET_STAFF_DONE = 'equipment/GET_STAFF_DONE'
export const GET_STAFF_FAIL = 'equipment/GET_STAFF_FAIL'

export const DELETE_REQUEST = 'equipment/DELETE_REQUEST'
export const DELETE_REQUEST_DONE = 'equipment/DELETE_REQUEST_DONE'
export const DELETE_REQUEST_FAIL = 'equipment/DELETE_REQUEST_FAIL'

export const SET_BRANCH_CONSENT = 'equipment/SET_BRANCH_CONSENT'
export const SET_BRANCH_CONSENT_DONE = 'equipment/SET_BRANCH_CONSENT_DONE'
export const SET_BRANCH_CONSENT_FAIL = 'equipment/SET_BRANCH_CONSENT_FAIL'