import cl from 'classnames'
import styles from './Modal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { equipment1Thunks } from '@/entity'
import { LoadingScreen } from '@/components/Loader'
import { FieldArray, Form, Formik, Field as FormikField } from 'formik'
import { Button, Checkbox, Field } from '@/ui'
import { isEmpty } from 'lodash'
import { FormControlLabel } from '@mui/material'
import { Alert } from '@/components/Alert/Alert'
import { Icon } from '@/components/Icon'
import { InfoGreen } from '@app/icons'

const FORM_NAME = {
	orgs: 'orgs',
}

export const BranchModal = ({
															onClose,
															onSubmit,
														}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { branchesData, equipment } = useSelector((state) => ({
		branchesData: state.equipment1.branches,
		equipment: state.equipment1.oneEquipment,
	}))

	useEffect(() => {
		let body = {
			onSuccess: async (res) => {
				await handleFormSubmit(res, res)
			}
		}

		if(!branchesData?.data) dispatch(equipment1Thunks.getBranches(body))
	}, [])

	const handleFormSubmit = (data, branchesArray) => {
		const array = branchesArray?.filter((branch, branchIdx) => data[branchIdx])

		const res = {
			[FORM_NAME.orgs]: array.map(({staus, key, ...otherItem}) => ({...otherItem}))
		}

		onSubmit(res)
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper, styles.modal_wrapperSizeM)}>
			<ModalBackButton onClick={onClose} />

			<h4>
				{t('occupational_risks_assessment.steps.step_1.add_object_title')}
			</h4>

			<div className={cl('my-4')}>
				<Alert
					icon={<Icon component={InfoGreen} />}
					text={t('occupational_risks_assessment.steps.step_1.branch_alert')}
				/>
			</div>

			<div className={cl(styles.wrapper, 'scroll')}>
				{branchesData.loading
					? <LoadingScreen />
					: (
						<div>
							<Formik
								initialValues={{
									[FORM_NAME.orgs]: branchesData?.data?.map(item => {
										let isChecked = equipment.data?.orgs?.find(org => org.bin === item.bin)

										if (isChecked) {
											return true
										}

										if (item.isHead) {
											return true
										} else {
											return false
										}
									}),
								}}
								onSubmit={(data) => handleFormSubmit(data.orgs, branchesData.data || [])}
								enableReinitialize={true}
							>
								{({ values, errors }) => {
									return (
										<Form>
											<FieldArray name={FORM_NAME.orgs}>
												{() => (
													<div className={cl('flex', 'flex-column')}>
														{branchesData.data?.map((branch, branchIdx) => {
															return (
																<div key={branch.key}>
																	<FormikField name={`${FORM_NAME.orgs}.${branchIdx}`}>
																		{({ field }) => {
																			return (
																				<FormControlLabel
																					disabled={branch.isHead}
																					control={<Checkbox checked={field?.value} />}
																					label={branch.orgNameRu}
																					value={branch.key}
																					style={{ margin: 0 }}
																					{...field}
																				/>
																			)
																		}}
																	</FormikField>
																</div>
															)
														})}
													</div>
												)}

											</FieldArray>


											<div className={cl('flex', 'justify-content-end')}>
												<Button type="submit"
																disabled={!isEmpty(errors)}
												>
													{t('save')}
												</Button>
											</div>
										</Form>
									)
								}}
							</Formik>
						</div>
					)}


			</div>
		</Modal>
	)
}