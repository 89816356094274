import cl from 'classnames'
import styles from '@features/occupationalRisks/Modal.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Modal'
import { Form, Formik, Field as FormikField } from 'formik'
import { Button, Field } from '@/ui'
import { isEmpty } from 'lodash'
import { FormingAgreementModalSchema } from '@features/regulatoryInformation/Modal.schema'

const FORM_NAME = {
	applicationExternalId: 'applicationExternalId',
	bankNameKz: 'bankNameKz',
	bankNameRu: 'bankNameRu',
	bik: 'bik',
	customerPhone: 'customerPhone',
	externalId: 'externalId',
	iik: 'iik',
	kbe: 'kbe',
	signingReasonKz: 'signingReasonKz',
	signingReasonRu: 'signingReasonRu',
	directorFullName: 'directorFullName',
}

export const FormingAgreementModal = ({
																				item,
																				onClose,
																				onSubmit,
																			}) => {
	const { t } = useTranslation()

	const handleFormSubmit = (data) => {
		onSubmit(data)
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper)}>
				<h4 className={cl('mb-4')}>
					{t('occupational_risks_assessment.steps.step_5.title')}
				</h4>

				<div className={cl(styles.wrapper, 'scroll')}>
					<Formik
						initialValues={{}}
						onSubmit={handleFormSubmit}
						enableReinitialize={true}
						validationSchema={FormingAgreementModalSchema}
					>
						{({ values, errors }) => {
							return (
								<Form>
									<div className={cl('flex', 'flex-column', 'gap-4')}>
										<div className={cl('flex', 'gap-4')}>
											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.signingReasonRu}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_1')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_1_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>

											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.signingReasonKz}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_1_kz')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_1_kz_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
										</div>

										<div className={cl('flex', 'gap-4')}>
											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.directorFullName}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_2')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_2_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
											<div>
												<FormikField name={FORM_NAME.customerPhone}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_3')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_3_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
										</div>

										<h5 className={cl('mt-4')}>
											{t('occupational_risks_assessment.steps.step_5.bank')}
										</h5>

										<div className={cl('flex', 'gap-4')}>
											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.bik}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_4')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_4_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.iik}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_5')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_5_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.kbe}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_6')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_6_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
										</div>

										<div className={cl('flex', 'gap-4')}>
											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.bankNameRu}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_7')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_7_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
											<div className={cl('flex-1')}>
												<FormikField name={FORM_NAME.bankNameKz}>
													{({ field, meta: { touched, error } }) => (
														<Field
															type="text"
															fieldType="input"
															label={`${t('occupational_risks_assessment.steps.step_5.field_7_kz')}*`}
															placeholder={t('occupational_risks_assessment.steps.step_5.field_7_kz_placeholder')}
															error={touched && error}
															{...field}
														/>
													)}
												</FormikField>
											</div>
										</div>

										<div className={cl('flex', 'gap-4', 'justify-content-end')}>
											<Button type="bare" onClick={onClose}>
												{t('cancel')}
											</Button>

											<Button type="submit" disabled={!isEmpty(errors)}>
												{t('occupational_risks_assessment.steps.step_5.create')}
											</Button>
										</div>
									</div>
								</Form>
							)
						}}
					</Formik>

				</div>
			</Modal>
	)
}