import React, { useMemo } from 'react'
import { Context } from './Context'
import { RenderTableColumns } from './RenderTableColumns'
import styles from './Table.module.scss'
import cl from 'classnames'
import { useTranslation } from 'react-i18next'

export const Table = ({ children, data, tableName = 'table', emptyPlaceholder = '', onRowClick, tableClassName, wrapperClassName }) => {
	const { t } = useTranslation()

	const contextValue = useMemo(
		() => ({
			data,
			tableName,
			emptyPlaceholder,
		}),
		[data, emptyPlaceholder, tableName]
	)

	return (
		<div className={cl(styles.wrapper, wrapperClassName)}>
			<table className={cl(styles.table, tableClassName)}>
				<Context.Provider value={contextValue}>
					<RenderTableColumns onRowClick={onRowClick}>{children}</RenderTableColumns>
				</Context.Provider>
			</table>
			{data.length ? null :
				<h5 className={cl('text-center', 'mt-3')}>{t('occupational_risks_assessment.steps.step_2.empty_table')}</h5>
			}
		</div>
	)
}
