import React from 'react'

export const Attention = ({ className = '' }) => {
	return (
		<svg
			className={className}
			width="23"
			height="22"
			viewBox="0 0 23 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.0655 16.3736L14.7959 2.54112C14.1342 1.2821 12.8396 0.5 11.4173 0.5C9.99501 0.5 8.70046 1.2821 8.03872 2.54112L0.769164 16.3736C0.151167 17.5496 0.194025 18.9862 0.880861 20.1225C1.56755 21.2595 2.81938 21.9659 4.14776 21.9659H18.687C20.0152 21.9659 21.267 21.2596 21.9538 20.1228C22.6406 18.9862 22.6835 17.5496 22.0655 16.3736ZM20.0531 18.9745C19.7658 19.45 19.2423 19.7453 18.6869 19.7453H4.14768C3.59216 19.7453 3.06876 19.45 2.78149 18.9743C2.49429 18.4991 2.47645 17.8984 2.73485 17.4067L10.0045 3.57407C10.2811 3.04764 10.8226 2.72054 11.4173 2.72054C12.0121 2.72054 12.5535 3.04756 12.8301 3.57407L20.0998 17.4067C20.3582 17.8984 20.3403 18.4991 20.0531 18.9745Z"
				fill="#17B67C"
			/>
			<path
				d="M10.6836 6.7125C10.6836 6.30979 11.0101 5.98333 11.4128 5.98333C11.8155 5.98333 12.1419 6.30979 12.1419 6.7125V13.3892C12.1419 13.7919 11.8155 14.1183 11.4128 14.1183C11.0101 14.1183 10.6836 13.7919 10.6836 13.3892V6.7125Z"
				fill="#17B67C"
			/>
			<path
				d="M10.3327 16.9599C10.3327 16.3632 10.8164 15.8795 11.4131 15.8795C12.0098 15.8795 12.4935 16.3632 12.4935 16.9599C12.4935 17.5566 12.0098 18.0403 11.4131 18.0403C10.8164 18.0403 10.3327 17.5566 10.3327 16.9599Z"
				fill="#17B67C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5086 6.7125C10.5086 6.21314 10.9134 5.80833 11.4128 5.80833C11.9121 5.80833 12.3169 6.21314 12.3169 6.7125V13.3892C12.3169 13.8885 11.9121 14.2933 11.4128 14.2933C10.9134 14.2933 10.5086 13.8885 10.5086 13.3892V6.7125ZM11.4128 6.15833C11.1067 6.15833 10.8586 6.40644 10.8586 6.7125V13.3892C10.8586 13.6952 11.1067 13.9433 11.4128 13.9433C11.7188 13.9433 11.9669 13.6952 11.9669 13.3892V6.7125C11.9669 6.40644 11.7188 6.15833 11.4128 6.15833ZM10.1577 16.9599C10.1577 16.2666 10.7198 15.7045 11.4131 15.7045C12.1064 15.7045 12.6685 16.2666 12.6685 16.9599C12.6685 17.6533 12.1064 18.2153 11.4131 18.2153C10.7198 18.2153 10.1577 17.6533 10.1577 16.9599ZM11.4131 16.0545C10.9131 16.0545 10.5077 16.4599 10.5077 16.9599C10.5077 17.46 10.9131 17.8653 11.4131 17.8653C11.9131 17.8653 12.3185 17.46 12.3185 16.9599C12.3185 16.4599 11.9131 16.0545 11.4131 16.0545Z"
				fill="#17B67C"
			/>
		</svg>
	)
}
