import * as types from './types'

export const actions = {
	createEditEquipmentAction: (payload) => ({
		type: types.CREATE_EDIT_EQUIPMENT,
	}),

	createEditEquipmentActionDone: (payload) => ({
		type: types.CREATE_EDIT_EQUIPMENT_DONE,
		payload,
	}),

	createEditEquipmentActionFail: (payload) => ({
		type: types.CREATE_EDIT_EQUIPMENT_FAIL,
	}),



	importEquipmentAction: (payload) => ({
		type: types.IMPORT_EQUIPMENT,
	}),

	importEquipmentActionDone: (payload) => ({
		type: types.IMPORT_EQUIPMENT_DONE,
		payload,
	}),

	importEquipmentActionFail: (payload) => ({
		type: types.IMPORT_EQUIPMENT_FAIL,
	}),



	getEquipmentAction: (payload) => ({
		type: types.GET_EQUIPMENT,
	}),

	getEquipmentActionDone: (payload) => ({
		type: types.GET_EQUIPMENT_DONE,
		payload,
	}),

	getEquipmentActionFail: (payload) => ({
		type: types.GET_EQUIPMENT_FAIL,
	}),



	getDownloadAction: (payload) => ({
		type: types.GET_DOWNLOAD,
	}),

	getDownloadActionDone: (payload) => ({
		type: types.GET_DOWNLOAD_DONE,
		payload,
	}),

	getDownloadActionFail: (payload) => ({
		type: types.GET_DOWNLOAD_FAIL,
	}),
}
