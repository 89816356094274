export const CREATE_EDIT_EQUIPMENT = 'protectiveEquipment/EDIT_EQUIPMENT'
export const CREATE_EDIT_EQUIPMENT_DONE = 'protectiveEquipment/EDIT_EQUIPMENT_DONE'
export const CREATE_EDIT_EQUIPMENT_FAIL = 'protectiveEquipment/EDIT_EQUIPMENT_FAIL'

export const IMPORT_EQUIPMENT = 'protectiveEquipment/IMPORT_EQUIPMENT'
export const IMPORT_EQUIPMENT_DONE = 'protectiveEquipment/IMPORT_EQUIPMENT_DONE'
export const IMPORT_EQUIPMENT_FAIL = 'protectiveEquipment/IMPORT_EQUIPMENT_FAIL'

export const GET_EQUIPMENT = 'protectiveEquipment/GET_EQUIPMENT'
export const GET_EQUIPMENT_DONE = 'protectiveEquipment/GET_EQUIPMENT_DONE'
export const GET_EQUIPMENT_FAIL = 'protectiveEquipment/GET_EQUIPMENT_FAIL'

export const GET_DOWNLOAD = 'protectiveEquipment/GET_DOWNLOAD'
export const GET_DOWNLOAD_DONE = 'protectiveEquipment/GET_DOWNLOAD_DONE'
export const GET_DOWNLOAD_FAIL = 'protectiveEquipment/GET_DOWNLOAD_FAIL'