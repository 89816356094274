export const DOWNLOAD_DOC = 'equipment/contract/DOWNLOAD_DOC'
export const DOWNLOAD_DOC_DONE = 'equipment/contract/DOWNLOAD_DOC_DONE'
export const DOWNLOAD_DOC_FAIL = 'equipment/contract/DOWNLOAD_DOC_FAIL'

export const SET_CREATE_UPDATE = 'equipment/contract/SET_CREATE_UPDATE'
export const SET_CREATE_UPDATE_DONE = 'equipment/contract/SET_CREATE_UPDATE_DONE'
export const SET_CREATE_UPDATE_FAIL = 'equipment/contract/SET_CREATE_UPDATE_FAIL'

export const DELETE_DATA = 'equipment/contract/DELETE_DATA'
export const DELETE_DATA_DONE = 'equipment/contract/DELETE_DATA_DONE'
export const DELETE_DATA_FAIL = 'equipment/contract/DELETE_DATA_FAIL'

export const SET_XML = 'equipment/contract/SET_XML'
export const SET_XML_DONE = 'equipment/contract/SET_XML_DONE'
export const SET_XML_FAIL = 'equipment/contract/SET_XML_FAIL'

export const SET_XML_SEND = 'equipment/contract/SET_XML_SEND'
export const SET_XML_SEND_DONE = 'equipment/contract/SET_XML_SEND_DONE'
export const SET_XML_SEND_FAIL = 'equipment/contract/SET_XML_SEND_FAIL'