import { api } from '@/shared'
import { message } from 'antd'
import { equipmentContractActions } from '@/entity/regulatoryInformation/contract/index'
import { downloadFileByUid } from '@/shared/api/rest/pension/downloadFileByUid'

export const getDownloadFile = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipmentContractActions.getDownloadFileAction())

			const res = await downloadFileByUid(data)

			if(res){
				dispatch(equipmentContractActions.getDownloadFileActionDone(res))
				onSuccess(res)
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipmentContractActions.getDownloadFileActionFail())
		}
	}
}

export const setCreateUpdate = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipmentContractActions.setCreateUpdateAction())

			const res = await api.regulatoryInformation.contract.createEditData(data)

			if(res){
				dispatch(equipmentContractActions.setCreateUpdateActionDone(res))
				onSuccess(res)
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipmentContractActions.setCreateUpdateActionFail())
		}
	}
}

export const deleteData = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipmentContractActions.deleteDataAction())

			const res = await api.regulatoryInformation.contract.deleteDoc(data)

			dispatch(equipmentContractActions.deleteDataActionDone(res))
			onSuccess()
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipmentContractActions.deleteDataActionFail())
		}
	}
}


export const setXml = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipmentContractActions.setXmlAction())

			const res = await api.regulatoryInformation.contract.xml(data)

			if(res){
				dispatch(equipmentContractActions.setXmlActionDone(res))
				onSuccess(res)
			}
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipmentContractActions.setXmlActionFail())
		}
	}
}


export const setXmlSend = (payload) => {
	const {data, onSuccess, onError} = payload

	return async (dispatch) => {
		try {
			dispatch(equipmentContractActions.setXmlSendAction())

			const res = await api.regulatoryInformation.contract.send(data)

			dispatch(equipmentContractActions.setXmlSendActionDone(res))
			onSuccess()
		} catch (error) {
			message.error(error.response?.data?.message)

			dispatch(equipmentContractActions.setXmlSendActionFail())
		}
	}
}