import cl from 'classnames'
import styles from './Modal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import React, { useEffect } from 'react'
import { FieldArray, Form, Formik, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button, Field } from '@/ui'
import plusIcon from '@/assets/icon/plus.svg'
import { PPMEquipmentEditCreateSchema } from '@features/regulatoryInformation/Modal.schema'
import { isEmpty } from 'lodash'
import { equipment1Thunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'

const FORM_NAME = {
	dicts: 'dicts',

	nameKz: 'nameKz',
	nameRu: 'nameRu',
	isCert: 'isCert',
	isCompliantWithStandards: 'isCompliantWithStandards',
	issueRateInMonths: 'issueRateInMonths',
	normativDocument: 'normativDocument',

	status: 'status',
}

export const PPMEquipmentEditModal = ({
																				item: currentItem,
																				onClose,
																				onSubmit,
																				type = TYPES.CREATE,
																			}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { item } = currentItem

	const { equipmentSelect } = useSelector((state) => ({
		equipmentSelect: state.equipment1.equipmentSelect,
	}))


	const handleFormSubmit = (data) => {
		let body = {
			...data,
			[FORM_NAME.dicts]: data[FORM_NAME.dicts].map(i => ({
				...i,
				[FORM_NAME.status]: item ? 'edit' : 'create',
			})),
		}

		onSubmit(body)
	}

	const initialState = {
		[FORM_NAME.nameKz]: item?.[FORM_NAME.nameKz],
		[FORM_NAME.nameRu]: item?.[FORM_NAME.nameRu],
		[FORM_NAME.isCert]: item?.[FORM_NAME.isCert] || false,
		[FORM_NAME.isCompliantWithStandards]: item?.[FORM_NAME.isCompliantWithStandards] || false,
		[FORM_NAME.issueRateInMonths]: item?.[FORM_NAME.issueRateInMonths],
		[FORM_NAME.normativDocument]: item?.[FORM_NAME.normativDocument],
	}

	const formInitialState = {
		dicts: [initialState],
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper)}>
			<ModalBackButton onClick={onClose} />

			<div className={cl(styles.wrapper, 'scroll')}>
				<h4>
					{t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.title')}
				</h4>

				<div className={cl(styles.wrapper, 'scroll')}>
					<Formik
						initialValues={formInitialState}
						onSubmit={handleFormSubmit}
						enableReinitialize={true}
						validationSchema={PPMEquipmentEditCreateSchema}
					>
						{({ values, errors }) => {
							return (
								<Form>
									<FieldArray name={FORM_NAME.dicts}>
										{({ remove, push }) => {
											return (
												<div className={cl('flex', 'flex-column', 'gap-5')}>
													{
														values?.dicts?.map((field, fieldIdx) => {
															return (
																<div key={fieldIdx} className={cl('flex', 'flex-column', 'gap-3')}>
																	<div className={cl('flex', 'justify-content-between')}>
																		<h2>
																			{t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.object')} {values?.dicts?.length >= 2 ? fieldIdx + 1 : ''}
																		</h2>
																		{
																			fieldIdx !== 0 && (
																				<Button
																					type="bare"
																					onClick={() => {
																						remove(fieldIdx)
																					}}>
																					{t('remove')}
																				</Button>
																			)
																		}
																	</div>

																	<div className={cl('flex')}>
																		<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.nameKz}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					label={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_1')}
																					placeholder={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_1')}
																					error={touched && error}
																				/>
																			)}
																		</FormikField>
																	</div>

																	<div className={cl('flex')}>
																		<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.nameRu}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					label={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_2')}
																					placeholder={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_2')}
																					error={touched && error}
																				/>
																			)}
																		</FormikField>
																	</div>

																	<div className={cl('flex')}>
																		<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.normativDocument}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					label={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_3')}
																					placeholder={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_3')}
																					error={touched && error}
																				/>
																			)}
																		</FormikField>
																	</div>

																	<div className={cl('flex')}>
																		<FormikField name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.issueRateInMonths}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					label={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_4')}
																					placeholder={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_4')}
																					error={touched && error}
																				/>
																			)}
																		</FormikField>
																	</div>

																	<div className={cl('flex', 'gap-5')}>
																		<FormikField
																			name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.isCompliantWithStandards}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					fieldType="twoRadio"
																					isBoolean
																					firstId={1}
																					secondId={2}
																					label={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_5')}
																					placeholder={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_5')}
																					error={touched && error}
																				/>
																			)}
																		</FormikField>

																		<FormikField
																			name={`${FORM_NAME.dicts}.${fieldIdx}.${FORM_NAME.isCert}`}>
																			{({ field, meta: { touched, error } }) => (
																				<Field
																					{...field}
																					fieldType="twoRadio"
																					isBoolean
																					firstId={10}
																					secondId={20}
																					label={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_6')}
																					placeholder={t('occupational_risks_assessment.regulatory_information.protective_equipment_modal.field_6')}
																					error={touched && error}
																				/>
																			)}
																		</FormikField>
																	</div>
																</div>
															)
														})
													}

													<div>
														<Button
															buttonClassName={cl('mt-4')}
															textColor="green"
															borderColor="green"
															transparent
															border
															icon={plusIcon}
															onClick={() => push(initialState)}>
															{t('occupational_risks_assessment.add_equip')}
														</Button>
													</div>
												</div>
											)
										}}
									</FieldArray>

									<div className={cl('flex', 'justify-content-end')}>
										<Button type="submit"
														disabled={!isEmpty(errors)}
										>
											{t('save')}
										</Button>
									</div>
								</Form>
							)
						}}
					</Formik>
				</div>
			</div>
		</Modal>
	)
}