import * as types from './types'
import { GET_CHECK_DOC_BY_ID_DONE, GET_CHECK_DOC_BY_ID_FAIL, SET_XML } from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null
}

const initialState = {
	document: data,
	createOrUpdate: data,
	xml: data
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.DOWNLOAD_DOC:
			return { ...state, document: {...data, loading: true} }

		case types.DOWNLOAD_DOC_DONE:
			return { ...state, document: {...data, success: true, data: payload} }

		case types.DOWNLOAD_DOC_FAIL:
			return { ...state, document: {...data, failed: true} }


		case types.SET_CREATE_UPDATE:
			return { ...state, createOrUpdate: {...data, loading: true} }

		case types.SET_CREATE_UPDATE_DONE:
			return { ...state, createOrUpdate: {...data, success: true, data: payload} }

		case types.SET_CREATE_UPDATE_FAIL:
			return { ...state, createOrUpdate: {...data, failed: true} }


		case types.SET_XML:
			return { ...state, xml: {...data, loading: true} }

		case types.SET_XML_DONE:
			return { ...state, xml: {...data, success: true, data: payload} }

		case types.SET_XML_FAIL:
			return { ...state, xml: {...data, failed: true} }


		case types.SET_XML_SEND:
			return { ...state, xml: {...data, loading: true} }

		case types.SET_XML_SEND_DONE:
			return { ...state, xml: {...data, success: true, data: payload} }

		case types.SET_XML_SEND_FAIL:
			return { ...state, xml: {...data, failed: true} }

		default:
			return state
	}
}