import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import moment from 'moment/moment'
import { Accordion, AccordionDetails } from '@mui/material'
import React, { useMemo } from 'react'
import { getCurrentLanguage } from '@/i18next'
import { useTranslation } from 'react-i18next'
import { ContractReviewsItem, RatingCount } from '@/features'
import styles from './MedicalCard.module.scss'
import cl from 'classnames'
import { DataGridTable } from '@/ui'
import dayjs from 'dayjs'

const langs = {
	rus: 'rus',
	kz: 'kz',
}

const statuses = {
	APPROVED: 'APPROVED',
	DISAPPROVED: 'DISAPPROVED',
	EXPIRED: 'EXPIRED',
}

export const MedicalCard = ({ data }) => {
	const lang = getCurrentLanguage()
	const optionLang = lang === 'rus' ? 'nameRu' : 'nameKz'

	const { t } = useTranslation()

	let statusLang = {
		[statuses.APPROVED]: t('medical_card.status_approved'),
		[statuses.DISAPPROVED]: t('medical_card.status_disapproved'),
		[statuses.EXPIRED]: t('medical_card.status_expired'),
	}

	const returnArray = (item) => {
		let arr = [
			{ title: t('medical_card.lmk_series'), data: item.medicalBookletSeries },
			{ title: t('medical_card.workplace'), data: item.workPlace },
			// { title: t('medical_card.target_group'), data: null },
			{ title: t('medical_card.position'), data: item.position },
			{ title: t('medical_card.doctor_total'), data: statusLang?.[item.status] },
			{ title: t('medical_card.period_start'), data: dayjs(item.startDate).format('DD.MM.YYYY') },
			{ title: t('medical_card.period_end'), data: dayjs(item.endDate).format('DD.MM.YYYY') },
			{ title: t('medical_card.doctor'), data: item.doctorName }
		]

		if(item.medOrgNameKk && item.medOrgNameRu){
			arr.push({ title: t('medical_card.medical_org'), data: lang === 'rus' ? item.medOrgNameRu : item.medOrgNameKk })
		}

		return arr
	}

	return (
		<Accordion className={styles.accordion} defaultExpanded={true}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
				className={styles.accordion__summary}
			>
				<Typography>
					<div>
						{data.method[optionLang]}{' '}
						<span className={styles.date__info}>{` - ${t('last_update')}: ${moment(data.requestDate).format(
							'DD.MM.YYYY'
						)}`}</span>
					</div>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<div className={styles.wrapper}>
					{data.data?.lmkWorkAdmissionDto?.map(item => (
						<div key={item.activityCode}>
							<DataGridTable
								hideSubTitle
								information={returnArray(item)}
								hideTitle
								titleClass={styles.tableTitleCell}
								rowClass={styles.dataRow}
							/>
						</div>
					))}

				</div>
			</AccordionDetails>
		</Accordion>
	)
}