import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/ui'
import addWhiteIcon from '@/assets/pages/personalAffairs/addWhite.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from '@/components/Icon'
import { Edit, Trash, View } from '@app/icons'
import styles from '@pages/RegulatoryInformation1/RegulatoryInformation1.module.scss'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import cl from 'classnames'
import { Table, TableColumn } from '@/components/Table'
import { equipment1Thunks } from '@/entity'
import { HrPagination } from '@/components/Pagination'
import { LoadingBlocker } from '@/components/Loader'
import { useHistory } from "react-router-dom";
import { message } from 'antd'
import { handbookThunks } from '@/entity/handbook'
import { getCurrentLanguage } from '@/i18next'

const ACTIONS = {
	VIEW: 'VIEW',
	DELETE: 'DELETE',
}

const initialState = {
	item: null,
	action: {
		[ACTIONS.VIEW]: false,
		[ACTIONS.DELETE]: false,
	},
}

const queryInitialState = {
	srcText: null,
	page: 0,
	size: 10,

	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const LANG = {
	rus: 'rus',
	kz: 'kz',
}

export const OccupationalRisksAssessment = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory();
	const lang = getCurrentLanguage()

	const { equipmentData, applicationStatus } = useSelector((state) => ({
		equipmentData: state.equipment1.equipments,
		applicationStatus: state.handbook.applicationStatus,
	}))

	const [queryParams, setQueryParams] = useState(queryInitialState)

	const [modalData, setModalData] = useState(initialState)

	useEffect(() => {
		getEquipment(queryParams)

		if (!applicationStatus.length) dispatch(handbookThunks.getApplicationStatus())
	}, [])

	const getEquipment = (params) => {
		dispatch(equipment1Thunks.getEquipment(params))
	}

	const handleChangePage = (e, page) => {
		setQueryParams({ ...queryParams, page: page - 1 })

		let params = {
			...queryParams,
			page: page - 1,
		}

		getEquipment(params)
	}

	const handleChangeRowsPerPage = (size) => {
		setQueryParams((prev) => ({ ...prev, page: 0, size: size }))

		let params = {
			...queryParams,
			page: 0,
			size: size,
		}

		getEquipment(params)
	}

	const columns = useMemo(() => {
		return [
			{
				title: t('occupational_risks_assessment.ora_table.field_1'),
				key: 'externalId',
				render: (item, record) => {
					return item
				},
			},
			{
				title: t('occupational_risks_assessment.ora_table.field_2'),
				key: 'orgName',
				render: (item, record) => {
					return item
				},
			},
			{
				title: t('occupational_risks_assessment.ora_table.field_3'),
				key: 'date',
				render: (item, record) => {
					return item
				},
			},
			{
				title: t('occupational_risks_assessment.ora_table.field_4'),
				key: 'plannedDays',
				render: (item, record) => {
					return item
				},
			},
			{
				title: t('occupational_risks_assessment.ora_table.field_5'),
				key: 'price',
				render: (item, record) => {
					return item
				},
			},
			{
				title: t('occupational_risks_assessment.ora_table.field_6'),
				key: 'status',
				render: (item, record) => {
					let status = applicationStatus?.find(i => i.code === item)
					let current = {
						[LANG.rus]: status?.nameRu,
						[LANG.kz]: status?.nameKz,
					}
					return current?.[lang]
				},
			},
			{
				title: '',
				key: 'actionRow',
				render: (item, record) => {
					return actionsRender(record)
				},
			},
		]
	}, [t, queryParams, applicationStatus])

	const actionsRender = useCallback((record) => {
		let actions = [
			{
				icon: <Icon component={View} className={styles.icon} />,
				title: t('actions.browsing'),
				action: ACTIONS.VIEW,
			},
			...(record.status === 'PROJECT' ? [{
				icon: <Icon component={Trash} className={styles.iconDelete} />,
				title: t('remove'),
				action: ACTIONS.DELETE,
			}] : []),
		]

		return (
			<DropDownMenu
				className={cl(styles.dropdown)}
				title={t('select_pls')}
				items={actions}
				onItemClick={(mode) => handleClickItem(record, mode)}
				onButtonClick={e => e.stopPropagation()}
				threeDots
			/>
		)
	}, [t, queryParams])

	const handleClickItem = (item, type) => {
		setModalData(prevState => ({
			...prevState,
			item: item,
			action: {
				...initialState.action,
				[type]: true,
			},
		}))

		if(type === ACTIONS.VIEW){
			history.push(`/applications-ora/view/${item.externalId}`)
		} else if(type === ACTIONS.DELETE){
			let body = {
				data: item.externalId,
				onSuccess: () => {
					message.success('Успешно!')
					getEquipment(queryParams)
				}
			}
			dispatch(equipment1Thunks.deleteRequest(body))
		}
	}

	const handleCreateNewRequest = () => {
		history.push('/applications-ora/create/new')
	}

	const onRowClick = (e, rowData, index) => {
		e.stopPropagation()

		const acceptArray = [
			'externalId',
			'orgName',
			'date',
			'plannedDays',
			'price',
			'status',
		]

		if(acceptArray.includes(e.target.dataset?.key)){
			handleClickItem(rowData, ACTIONS.VIEW)
		}
	}

	return (
		<>
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_occupational_risks_assessment')}>
					<div className={cl('flex', 'flex-column', 'gap-4')}>
						<div>
							<Button
								icon={addWhiteIcon}
								onClick={handleCreateNewRequest}
							>
								{t('occupational_risks_assessment.applications.create_new_app')}
							</Button>
						</div>

						<div>
							<Table
								tableName="table"
								data={equipmentData.data?.content || []}
								emptyPlaceholder="-"
								tableClassName={styles.table}
								wrapperClassName={styles.tableWrapper}
								onRowClick={onRowClick}
							>
								{(columns || []).map(item => (
									<TableColumn
										key={item.key}
										dataKey={item.key}
										heading={item.title}
										renderCell={(cell, record) => item.render ? item.render(cell, record) : cell}
									/>
								))}
							</Table>

							<HrPagination
								id="hr-pagination"
								rowsPerPageitems={queryParams.rowsPerPage}
								rowsPerPage={queryParams.size}
								numberOfElements={equipmentData?.data?.numberOfElements}
								totalElements={equipmentData?.data?.totalElements}
								totalPages={equipmentData?.data?.totalPages}
								handleChangePage={handleChangePage}
								handleChangeRowsPerPage={handleChangeRowsPerPage}
								page={queryParams.page}
							/>
						</div>
					</div>

				</ProfileTemplate>
			</RedirectUnAuthUser>

			{(equipmentData.loading) && <LoadingBlocker />}
		</>
	)
}
