import * as types from './types'

const data = {
	loading: false,
	success: false,
	failed: false,
	data: null
}

const initialState = {
	equipments: data,
	oneEquipment: data,
	branches: data,
	harmful: data,
	equipmentSelect: data,
	notification: data,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.GET_EQUIPMENT_DATA:
			return { ...state, equipments: {...data, loading: true} }

		case types.GET_EQUIPMENT_DATA_DONE:
			return { ...state, equipments: {...data, success: true, data: payload} }

		case types.GET_EQUIPMENT_DATA_FAIL:
			return { ...state, equipments: {...data, failed: true} }


		case types.GET_ONE_EQUIPMENT:
			return { ...state, oneEquipment: {...data, data:state.oneEquipment.data, loading: true} }

		case types.GET_ONE_EQUIPMENT_DONE:
			return { ...state, oneEquipment: {...data, success: true, data: payload} }

		case types.GET_ONE_EQUIPMENT_FAIL:
			return { ...state, oneEquipment: {...data, failed: true} }


		case types.GET_BRANCHES:
			return { ...state, branches: {...data, loading: true} }

		case types.GET_BRANCHES_DONE:
			return { ...state, branches: {...data, success: true, data: payload} }

		case types.GET_BRANCHES_FAIL:
			return { ...state, branches: {...data, failed: true} }


		case types.SET_EQUIPMENT:
			return { ...state, oneEquipment: {...data, data: state.oneEquipment.data, loading: true} }

		case types.SET_EQUIPMENT_DONE:
			return { ...state, oneEquipment: {...data, success: true, data: payload} }

		case types.SET_EQUIPMENT_FAIL:
			return { ...state, oneEquipment: {...data, failed: true} }


		case types.GET_STAFF:
			return { ...state, oneEquipment: {...data, data: state.oneEquipment.data, loading: true} }

		case types.GET_STAFF_DONE:
			return { ...state, oneEquipment: {...data, success: true, data: state.oneEquipment.data} }

		case types.GET_STAFF_FAIL:
			return { ...state, oneEquipment: {...data, failed: true} }


		case types.GET_XML:
		case types.POST_XML:
			return { ...state, oneEquipment: {...data, data: state.oneEquipment.data, loading: true} }

		case types.GET_XML_DONE:
		case types.POST_XML_DONE:
			return { ...state, oneEquipment: {...data, success: true, data: state.oneEquipment.data} }

		case types.GET_XML_FAIL:
		case types.POST_XML_FAIL:
			return { ...state, oneEquipment: {...data, failed: true} }


		case types.GET_HARMFUL:
			return { ...state, harmful: {...data, loading: true} }

		case types.GET_HARMFUL_DONE:
			return { ...state, harmful: {...data, success: true, data: payload} }

		case types.GET_HARMFUL_FAIL:
			return { ...state, harmful: {...data, failed: true} }


		case types.GET_EQUIPMENT_SELECT:
			return { ...state, equipmentSelect: {...data, loading: true} }

		case types.GET_EQUIPMENT_SELECT_DONE:
			return { ...state, equipmentSelect: {...data, success: true, data: payload} }

		case types.GET_EQUIPMENT_SELECT_FAIL:
			return { ...state, equipmentSelect: {...data, failed: true} }


		case types.SET_BRANCH_CONSENT:
			return { ...state, notification: {...data, loading: true} }

		case types.SET_BRANCH_CONSENT_DONE:
			return { ...state, notification: {...data, success: true, data: payload} }

		case types.SET_BRANCH_CONSENT_FAIL:
			return { ...state, notification: {...data, failed: true} }

		default:
			return state
	}
}