import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import { FieldArray, Form, Formik, Field as FormikField } from 'formik'
import { message } from 'antd'
import { equipment1Thunks } from '@/entity'
import { Button, Field } from '@/ui'
import React from 'react'
import { DigitalSign } from '@/utils/digitalSign'
import cl from 'classnames'
import { Alert } from '@/components/Alert/Alert'
import { Info } from '@app/icons'
import { Icon } from '@/components/Icon'
import styles from './styles.module.scss'
import { Step3Schema } from '@features/regulatoryInformation/Modal.schema'
import { isEmpty } from 'lodash'

const FORM_NAME = {
	plannedDays: 'plannedDays',
	totalServiceCost: 'totalServiceCost',
}

export const Step3 = ({
												onSuccessStep,
											}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const digitalSign = new DigitalSign()

	const { equipment } = useSelector((state) => ({
		equipment: state.equipment1.oneEquipment,
	}))

	const formInitialState = {
		[FORM_NAME.plannedDays]: equipment?.data?.plannedDays || null,
		[FORM_NAME.totalServiceCost]: equipment?.data?.totalServiceCost || null,
	}

	const onSubmitBranch = (data) => {
		let body = {
			data: {
				...equipment.data,
				...data,
			},
			onSuccess: async ({ xml }) => {
				const res = await digitalSign.handleSend(xml)

				let innerBody = {
					data: { data: res, externalId: equipment.data?.externalId},
					onSuccess: () => {
						onSuccessStep()
						message.success('Успешно!')
					},
				}

				dispatch(equipment1Thunks.postXmlSend(innerBody))
			},
			onError: () => {

			},
		}
		dispatch(equipment1Thunks.getXml(body))
	}

	return (
		<div className={cl('py-3')}>
			<Formik onSubmit={onSubmitBranch} initialValues={formInitialState} validationSchema={Step3Schema}>
				{({ values, errors }) => {
					return (
						<Form>
							<div className={cl('flex', 'flex-column', 'gap-3')}>
								<div className={cl('flex', 'gap-4')}>
									<FormikField name={FORM_NAME.plannedDays}>
										{({ field, meta: { touched, error } }) => (
											<Field
												{...field}
												type="number"
												min={0}
												hideNumberArrows
												error={touched && error}
												label={t('occupational_risks_assessment.steps.step_3.field_1')}
												disabled={equipment?.data?.status !== 'PROJECT'}
											/>
										)}
									</FormikField>
									<FormikField name={FORM_NAME.totalServiceCost}>
										{({ field, meta: { touched, error } }) => (
											<Field
												{...field}
												type="number"
												min={0}
												hideNumberArrows
												error={touched && error}
												label={t('occupational_risks_assessment.steps.step_3.field_2')}
												disabled={equipment?.data?.status !== 'PROJECT'}
											/>
										)}
									</FormikField>
								</div>

								{
									equipment?.data?.status === 'PROJECT' &&
									<>
										<div>
											<Alert
												icon={<Icon component={Info} className={styles.icon} />}
												text={(
													<>
														<p className={'mb-0'}>{t('occupational_risks_assessment.steps.step_3.info.first_row')}</p>
														<p className={'mb-0'}>{t('occupational_risks_assessment.steps.step_3.info.second_row')}</p>
														<p className={'mb-0'}>{t('occupational_risks_assessment.steps.step_3.info.third_row')}</p>
														<p className={'mb-0'}>{t('occupational_risks_assessment.steps.step_3.info.fourth_row')}</p>
														<p className={'mb-0'}>{t('occupational_risks_assessment.steps.step_3.info.fifth_row')}</p>
													</>
												)}
											/>

										</div>
										<div>
											<Button type="submit" disabled={!isEmpty(errors)}>
												{t('sign_and_send')}
											</Button>
										</div>
									</>
								}
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
}