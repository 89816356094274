import { BranchModal } from '@features/occupationalRisks'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { equipment1Thunks } from '@/entity'
import { Button } from '@/ui'
import cl from 'classnames'
import { useHistory } from 'react-router-dom'
import styles from '@pages/RegulatoryInformation1/RegulatoryInformation1.module.scss'
import { Table, TableColumn } from '@/components/Table'
import { message } from 'antd'
import { Icon } from '@/components/Icon'
import { Add, Edit } from '@app/icons'

const MODAL_TYPE = {
	BRANCH: 'BRANCH'
}

const modalInitialState = {
	[MODAL_TYPE.BRANCH]: false
}

export const Step1 = ({
												onSuccessStep
											}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory();

	const { equipment } = useSelector((state) => ({
		equipment: state.equipment1.oneEquipment,
	}))

	const [modalData ,setModalData] = useState(modalInitialState)

	const onSubmitBranch = (data) => {
		let body = {
			data: {
				...data,
				...(equipment?.data?.externalId ? {externalId: equipment?.data?.externalId} : {})
			},
			onSuccess: (res) => {
				history.replace(`/applications-ora/view/${res.externalId}`)
				handleModalClose()
				message.success('Успешно!')
			},
			onError: () => {
				handleModalClose()
			}
		}
		dispatch(equipment1Thunks.setEquipment(body))
	}

	const handleModalClose = () => {
		setModalData(modalInitialState)
	}

	const handleModalOpen = (key, value) => {
		setModalData(prevState => ({
			...prevState,
			[key]: value
		}))
	}

	const columns = useMemo(() => ([
		{
			title: t('occupational_risks_assessment.steps.step_1.step_1_table.field_1'),
			key: 'bin',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.steps.step_1.step_1_table.field_2'),
			key: 'orgName',
			render: (item, record) => {
				return record.orgNameRu
			},
		},
		{
			title: t('occupational_risks_assessment.steps.step_1.step_1_table.field_3'),
			key: 'address',
			render: (item, record) => {
				return record.address
			},
		},
		{
			title: t('occupational_risks_assessment.steps.step_1.step_1_table.field_4'),
			key: 'oked',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.steps.step_1.step_1_table.field_5'),
			key: 'secondryOked',
			render: (item, record) => {
				return
			},
		},
		{
			title: t('occupational_risks_assessment.steps.step_1.step_1_table.field_6'),
			key: 'staus',
			render: (item, record) => {
				let status = {
					'APPROVED': t('profUnions.approved'),
					'REJECTED': t('occupational_risks_assessment.steps.step_1.rejected'),
					'WAITING': t('occupational_risks_assessment.steps.step_1.waiting'),
				}

				return status?.[item] || ''
			},
		},
	]), [t])

	return (
		<>
			{modalData[MODAL_TYPE.BRANCH] && <BranchModal onClose={handleModalClose} onSubmit={(data) => onSubmitBranch(data)} />}

			<div className={cl('py-4')}>
				<div className={cl('flex', 'flex-column', 'gap-4')}>
					{
						equipment?.data?.status === 'PROJECT' &&
						<div>
							<Button
								icon={<Icon component={equipment?.data?.orgs?.length ? Edit : Add} />}
								onClick={() => handleModalOpen(MODAL_TYPE.BRANCH, true)}
							>
								{equipment?.data?.orgs?.length ? t('occupational_risks_assessment.steps.step_1.edit_object') : t('occupational_risks_assessment.steps.step_1.add_object')}
							</Button>
						</div>
					}

					{equipment.data?.orgs?.length ?
						<div>
							<Table
								tableName="table"
								data={equipment.data?.orgs || []}
								emptyPlaceholder="-"
								tableClassName={styles.table}
								wrapperClassName={styles.tableWrapper}
							>
								{(columns || []).map(item => (
									<TableColumn
										key={item.key}
										dataKey={item.key}
										heading={item.title}
										renderCell={(cell, record) => item.render ? item.render(cell, record) : cell}
									/>
								))}
							</Table>
						</div>
						: null
					}
				</div>
			</div>
		</>
	)
}