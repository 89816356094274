import { request } from '@/shared/api/request'

export const get = (payload) => {
	return request({ url: '/assessment', method: 'GET', data: payload })
}

export const getBranches = (payload) => {
	return request({ url: '/assessment/branches', method: 'GET', data: payload })
}

export const getStaff = (params) => {
	return request({ url: '/assessment/staff', method: 'GET', params: params })
}

export const getPdfView = (id) => {
	return request({ url: `/assessment/get/${id}`, method: 'GET' })
}

export const getAll = (params) => {
	return request({ url: '/assessment/page', method: 'GET', params })
}

export const getHarmful = () => {
	return request({ url: 'dict/harmful', method: 'GET' })
}

export const getDataByBin = (params) => request({ url: '/occupationalRisks/reorganize/check', method: 'GET', params })
