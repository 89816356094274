import { request } from '@/shared/api/request'

export const getData = (payload) => {
	return request({ url: `/assessment/dict/${payload}`, method: 'GET' })
}

export const get = (payload) => {
	return request({ url: '/assessment/dict/equipment', method: 'GET', data: payload })
}

export const getAll = (params) => {
	return request({ url: '/assessment/dict/personalProtectionMeans', method: 'GET', params: params })
}

export const getDownload = () => {
	return request({ url: '/assessment/dict/download', method: 'GET', params: { dictName: 'ppm' } })
}

export const getDataByBin = (params) => request({ url: '/occupationalRisks/reorganize/check', method: 'GET', params })
