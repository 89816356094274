import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import cl from 'classnames'
import { Alert } from '@/components/Alert/Alert'
import React, { useMemo } from 'react'
import { Icon } from '@/components/Icon'
import { Info, InfoGreen } from '@app/icons'
import styles from '@features/occupationalRisks/step/styles.module.scss'
import { getCurrentLanguage } from '@/i18next'
import { DataGridTable } from '@/ui'
import doc from '@/assets/home/doc.svg'
import { equipmentContractThunks } from '@/entity'
import { message } from 'antd'
import fileDownload from 'js-file-download'

const LANG = {
	rus: 'rus',
	kz: 'kz',
}

export const Step4 = ({
												onSuccessStep,
											}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()

	const { equipment, applicationStatus } = useSelector((state) => ({
		equipment: state.equipment1.oneEquipment,
		applicationStatus: state.handbook,
	}))

	const handleDownload = () => {
		let id = equipment?.data?.protocol?.protocolFileId
		let fileName = equipment?.data?.protocol?.protocolName

		let body = {
			data: id,
			onSuccess: (res) => {
				message.success('Успешно!')
				fileDownload(res, fileName)
			},
		}

		dispatch(equipmentContractThunks.getDownloadFile(body))
	}

	const headerData = useMemo(() => {
		let statusLabel = {
			[LANG.kz]: equipment?.data?.protocol?.soNameKz,
			[LANG.rus]: equipment?.data?.protocol?.soNameRu,
		}

		return [
			{
				title: t('occupational_risks_assessment.steps.step_4.protocol_field_1'),
				data: equipment?.data?.protocol?.soBin,
			},
			{ title: t('occupational_risks_assessment.steps.step_4.protocol_field_2'), data: statusLabel?.[lang] },
			{
				title: t('occupational_risks_assessment.steps.step_4.protocol_field_3'),
				data: equipment?.data?.protocol?.soAddress,
			},
			{
				title: t('occupational_risks_assessment.steps.step_4.protocol_field_4'),
				data: equipment?.data?.protocol?.soContacts,
			},
		]
	}, [equipment?.data, t, lang])

	const render = (
		<div>
			<DataGridTable
				hideSubTitle={true}
				hideTitle={false}
				title={'form_ora_app'}
				information={headerData || []}
				titleClass={styles.tableTitleCell}
			/>

			<div className={cl('mt-4')}>
				<div className={cl('flex', 'gap-3', 'align-center')}>
					<img src={doc} />
					<span>{equipment?.data?.protocol?.protocolName}</span>
					<span role="button" className={styles.download} onClick={handleDownload}>{t('labor_records.download')}</span>
				</div>
			</div>
		</div>
	)

	const statuses = useMemo(() => {
		return {
			UNDER_REVIEW: (
				<Alert
					icon={<Icon component={InfoGreen} />}
					text={t('occupational_risks_assessment.steps.step_4.alertSelectedSo')}
				/>
			),
			SELECTED_SO: equipment?.data?.protocol ? (render) : (
				<Alert
					icon={<Icon component={InfoGreen} />}
					text={t('occupational_risks_assessment.steps.step_4.alertUnderReview')}
				/>
			),
		}
	}, [equipment?.data])

	return (
		<div className={cl('py-4')}>
			{statuses[equipment?.data?.status] || render}
		</div>
	)
}