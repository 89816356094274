import cl from 'classnames'
import styles from '@features/occupationalRisks/Modal.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBackButton } from '@/components/Modal'
import PdfViewer from '@/entity/contracts/ui/form/employmentСontractFile/PdfViewer/PdfViewer'
import { Button } from '@/ui'
import fileDownload from 'js-file-download'

export const ElectronicContractModal = ({
																				item,
																				onClose,
																				onSubmit,
																			}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { document, equipment } = useSelector((state) => ({
		document: state.equipmentContract?.document,
		equipment: state.equipment1.oneEquipment,
	}))

	const handleDownloadFile = () => {
		fileDownload(document.data, equipment.data?.contractStatus?.fileName)
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper)}>
			<ModalBackButton onClick={onClose} />

			<div className={cl('flex', 'flex-column', 'gap-2', 'mb-4')}>
				<h4 className={'mb-0'}>{t('occupational_risks_assessment.steps.step_5.e_col_agreement')}</h4>
				<span>ID {equipment?.data?.contractStatus?.externalId}</span>

				<span role='button' onClick={handleDownloadFile} style={{color: '#17B67C'}}>{t('labor_records.download')}</span>
			</div>

			<div className={cl(styles.wrapper)}>
				<PdfViewer isPdfFile={document?.data} />
			</div>

			<div className={cl('mt-4', 'flex', 'justify-content-end')}>
				<Button onClick={onClose}>
					{t('close')}
				</Button>
			</div>
		</Modal>
	)
}