import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { mainReducer } from './main'
import { userReducer } from '@/entity/user'
import { contractsReducer } from '@/entity/contracts'
import { handbookReducer } from '@/entity/handbook'
import { companyReducer } from '@/entity/company'
import { notificationsReducer } from '@/entity/notifications'
import { CLEAR_XML, clearXmlReducer } from './GlobalStoreUtils'
import { affairsReducer } from '@/entity/personalAffairs'
import { formsReducer } from '@/entity/forms'
import { eContractsReducer } from '@/entity/eContracts'
import { opvReducer } from '@/entity/opv'
import { laborRecordsReducer } from '@/entity/laborRecords'
import { branchReducer } from '@/entity/branch'
import { transferReducer } from '@/entity/transfer'
import { pensionReducer } from '@/entity/pension'
import { dictReducer } from './dict'
import { oppvReducer } from '@/entity/oppv'
import { verificationReducer } from '@/entity/verification'
import { colContractsReducer } from '@/entity/collectiveContracts'
import { staffReducer } from '@/entity/staffingProject'
import { staffingReducer } from '@/entity/staffing'
import { profUnionsReducer } from '@/entity/profUnions'
import { sideBarReducer } from '@/entity/sideBar'
import { accidentReducer } from '@/entity'
import { positionEvaluationReducer } from '@/entity/grading/positionEvaluation/model'
import { eColContractsReducer } from '@/entity/eCollectiveContracts'
import { agreementsReducer } from '@/entity/industryAgreement'
import { reviewReducer } from '@/entity/review'
import { administrationReducer } from '@/entity/administration'
import { protectiveEquipment1Reducer, equipment1Reducer, equipmentContractReducer } from '@/entity/regulatoryInformation'
import { sickLeaveReducer } from '@/entity/sickLeave'
import { requirementLaborResourcesReducer } from '@/entity/requirementLaborResources'
import { referenceInformationReducer } from '@/entity/referenceInformation'

export const appReducer = combineReducers({
	auth: authReducer,
	main: mainReducer,
	user: userReducer,
	contracts: contractsReducer,
	eContracts: eContractsReducer,
	handbook: handbookReducer,
	company: companyReducer,
	forms: formsReducer,
	opv: opvReducer,
	notifications: notificationsReducer,
	affairs: affairsReducer,
	laborRecords: laborRecordsReducer,
	branch: branchReducer,
	transfer: transferReducer,
	pension: pensionReducer,
	dict: dictReducer,
	oppv: oppvReducer,
	verification: verificationReducer,
	colContracts: colContractsReducer,
	staff: staffReducer,
	staffing: staffingReducer,
	profUnions: profUnionsReducer,
	sideBar: sideBarReducer,
	accidents: accidentReducer,
	positionEvaluations: positionEvaluationReducer,
	eColContracts: eColContractsReducer,
	industryAgreement: agreementsReducer,
	review: reviewReducer,
	administration: administrationReducer,
	equipment1: equipment1Reducer,
	protectiveEquipment1: protectiveEquipment1Reducer,
	sickLeave: sickLeaveReducer,
	requirementLaborResources: requirementLaborResourcesReducer,
	referenceInformation: referenceInformationReducer,
	equipmentContract: equipmentContractReducer,
})

export const rootReducer = (state, action) => {
	if (action.type === CLEAR_XML) {
		return appReducer(clearXmlReducer(state), action)
	}
	return appReducer(state, action)
}
