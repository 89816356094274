import { Modal, ModalBackButton } from '@/components/Modal'
import cl from 'classnames'
import styles from './Modal.module.scss'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Title } from '@/ui'


const FORM_NAME = {
	dicts: 'dicts',
	status: 'status',
}

export const DeleteModal = ({
															item: currentItem,
															onClose,
															onSubmit
														}) => {
	const { t } = useTranslation()

	const { item } = currentItem

	const handleFormSubmit = () => {
		const {creatorEmail, ...object} = item

		let body = {
			[FORM_NAME.dicts]: [{
				...object,
				[FORM_NAME.status]: 'delete',
			}],
		}

		onSubmit(body)
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper)}>
			<ModalBackButton onClick={onClose} />

			<div className={cl(styles.wrapper, 'scroll')}>
				<div className={cl('flex',' justify-content-center', 'mb-4')}>
					<Title>
						{t('confirm_delete')}
					</Title>
				</div>

				<div className={cl('flex', 'flex-column', 'gap-4')}>
					<div className={cl('flex', 'justify-content-center')}>
						<Button onClick={handleFormSubmit}>
							{t('labor_records.remove')}
						</Button>
					</div>

					<div className={cl('flex', 'justify-content-center')}>
						<Button type="bare" onClick={onClose}>
							{t('cancel')}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}