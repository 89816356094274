import * as types from './types'
import { GET_CHECK_DOC_BY_ID_DONE } from './types'

export const actions = {
	getDownloadFileAction: (payload) => ({
		type: types.DOWNLOAD_DOC,
	}),

	getDownloadFileActionDone: (payload) => ({
		type: types.DOWNLOAD_DOC_DONE,
		payload,
	}),

	getDownloadFileActionFail: (payload) => ({
		type: types.DOWNLOAD_DOC_FAIL,
	}),


	setCreateUpdateAction: (payload) => ({
		type: types.SET_CREATE_UPDATE,
	}),

	setCreateUpdateActionDone: (payload) => ({
		type: types.SET_CREATE_UPDATE_DONE,
		payload,
	}),

	setCreateUpdateActionFail: (payload) => ({
		type: types.SET_CREATE_UPDATE_FAIL,
	}),


	deleteDataAction: (payload) => ({
		type: types.DELETE_DATA,
	}),

	deleteDataActionDone: (payload) => ({
		type: types.DELETE_DATA_DONE,
		payload,
	}),

	deleteDataActionFail: (payload) => ({
		type: types.DELETE_DATA_FAIL,
	}),


	setXmlAction: (payload) => ({
		type: types.SET_XML,
	}),

	setXmlActionDone: (payload) => ({
		type: types.SET_XML_DONE,
		payload,
	}),

	setXmlActionFail: (payload) => ({
		type: types.SET_XML_FAIL,
	}),


	setXmlSendAction: (payload) => ({
		type: types.SET_XML_SEND,
	}),

	setXmlSendActionDone: (payload) => ({
		type: types.SET_XML_SEND_DONE,
		payload,
	}),

	setXmlSendActionFail: (payload) => ({
		type: types.SET_XML_SEND_FAIL,
	}),
}
