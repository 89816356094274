import cl from 'classnames'
import styles from '@features/occupationalRisks/Modal.module.scss'
import { Modal } from '@/components/Modal'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LoadingScreen } from '@/components/Loader'
import { Tab, TabsList, Tabs, TabPanel } from '@mui/base'
import { Tree } from 'antd'
import { Button } from '@/ui'

export const HarmfulModal = ({
															 item,
															 onClose,
															 onSubmit,
														 }) => {
	const { t } = useTranslation()

	const { harmful } = useSelector((state) => ({
		harmful: state.equipment1.harmful,
	}))

	const [selectedCheckbox, setSelectedCheckbox] = useState([])

	const tabs = useMemo(() => {
		const array = harmful.data?.map(obj => ({
			key: obj.code,
			label: obj.nameRu,
			children: obj.child,
		}))

		return array
	}, [harmful.data]) || []

	const [activeTab, setActiveTab] = useState(null)

	useEffect(() => {
		let arrayOfCode = item?.child?.dicts?.harmfulCodes?.map(i => i.code)
		setSelectedCheckbox(arrayOfCode || [])
	}, [])

	useEffect(() => {
		if (tabs.length) {
			setActiveTab(tabs[0].key)
		}
	}, [tabs])

	const handleChangeTab = (e, newValue) => {
		setActiveTab(newValue)
	}

	const handleTreeSelect = (selectedKeys, info) => {
		console.log('selected', selectedKeys, info)
	}

	const handleTreeCheck = (checkedKeys, info) => {
		setSelectedCheckbox(checkedKeys)
	}

	const getTreeData = useCallback((array) => {
		return array.map(obj => ({
			title: `${obj.code}.${obj.nameRu}`,
			key: obj.code,
			children: getTreeData(obj.child),
		}))
	}, [])

	const handleSubmitButton = () => {
		onSubmit(selectedCheckbox)
	}

	return (
		<Modal windowClassName={cl(styles.modal_wrapper)}>
			<h4>
				{t('occupational_risks_assessment.add_harmful')}
			</h4>

			<div className={cl(styles.wrapper, 'scroll')}>
				{harmful.loading
					? <LoadingScreen />
					: (
						<div className={cl('mt-4')}>
							<Tabs value={activeTab} onChange={handleChangeTab} className={cl(styles.tab)}>
								<TabsList className={cl('flex', 'flex-nowrap', 'scrollHorizontal')} style={{ overflowX: 'scroll' }}>
									{tabs.map((item) => (
										<Tab value={item?.key} key={item?.key} id={item.key} className={styles.tab__item}>
										<span className={cl('text-nowrap')}>
											{item?.label}
										</span>
										</Tab>
									))}
								</TabsList>

								<div className={cl('mt-4')}>
									{tabs.map((item) => {
										return (
											<TabPanel key={item.key} value={item.key}>
												<div>
													<Tree
														checkable
														showLine
														onSelect={handleTreeSelect}
														onCheck={handleTreeCheck}
														treeData={getTreeData(item.children)}
														checkedKeys={selectedCheckbox}
													/>
												</div>
											</TabPanel>
										)
									})}
								</div>
							</Tabs>

							<div className={cl('flex', 'justify-content-end', 'gap-4', 'mt-4')}>
								<Button type="bare" onClick={onClose}>
									{t('cancel')}
								</Button>

								<Button onClick={handleSubmitButton} disabled={!selectedCheckbox.length}>
									{t('add')}
								</Button>
							</div>
						</div>
					)}
			</div>
		</Modal>
	)
}