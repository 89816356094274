import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { equipment1Thunks } from '@/entity'
import styles from './styles.module.scss'
import { DataGridTable } from '@/ui'
import { LoadingBlocker } from '@/components/Loader'
import cl from 'classnames'
import { Step1 } from '@features/occupationalRisks/step/Step1'
import { Arrow } from '@app/icons'
import { Step2 } from '@features/occupationalRisks/step/Step2'
import { Icon } from '@/components/Icon'
import { Step3 } from '@features/occupationalRisks/step/Step3'
import { Step4 } from '@features/occupationalRisks/step/Step4'
import { Step5 } from '@features/occupationalRisks/step/Step5'
import { getCurrentLanguage } from '@/i18next'
import { message } from 'antd'
import { handbookSelectors, handbookThunks } from '@/entity/handbook'

const TYPES = {
	CREATE: 'create',
	VIEW: 'view',
}

const ACCORDION_TYPE = {
	passed: 'passed',
	current: 'current',
	disabled: 'disabled',
}

const LANG = {
	rus: 'rus',
	kz: 'kz',
}

const EQUIPMENT_STATUS = {
	PROJECT: 'PROJECT',
	UNDER_REVIEW: 'UNDER_REVIEW',
	SELECTED_SO: 'SELECTED_SO',
	AT_SIGNING: 'AT_SIGNING',
	SIGNED: 'SIGNED',
}

const ORGS_STATUS = {
	APPROVED: 'APPROVED',
	WAITING: 'WAITING',
	REJECTED: 'REJECTED',
}

const Accordion = ({
										 children,
										 isOpen,
										 onClick,
										 title,
										 icon,
									 }) => {

	return (
		<div className={cl('flex', 'flex-column')}>
			<div className={cl('flex', 'align-center', 'justify-content-between')} onClick={onClick} role="button">
				<div className={cl('flex', 'align-center', 'gap-4')}>
					{icon}
					<h5 className={cl('mb-0')}>{title}</h5>
				</div>

				<div>
					<Icon component={Arrow} className={cl(styles.icon, {
						[styles.iconOpen]: isOpen,
					})} />
				</div>
			</div>

			{isOpen && children}
		</div>
	)
}

const STEPS_STATUS = {
	1: {
		status: ACCORDION_TYPE.disabled,
		isOpen: false,
	},
	2: {
		status: ACCORDION_TYPE.disabled,
		isOpen: false,
	},
	3: {
		status: ACCORDION_TYPE.disabled,
		isOpen: false,
	},
	4: {
		status: ACCORDION_TYPE.disabled,
		isOpen: false,
	},
	5: {
		status: ACCORDION_TYPE.disabled,
		isOpen: false,
	},
}

export const NewOccupationalRisksAssessment = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const params = useParams()
	const history = useHistory()
	const lang = getCurrentLanguage()

	const { type, id } = params

	const { equipment, applicationStatus } = useSelector((state) => ({
		equipment: state.equipment1.oneEquipment,
		applicationStatus: state.handbook.applicationStatus,
	}))

	const [stepStatus, setStepStatus] = useState(STEPS_STATUS)

	const getOneEquipment = () => {
		let body = {
			data: {
				data: {},
				params: {},
				payload: {
					type: type,
					externalId: id,
				},
			},
			onSuccess: () => {

			},
			onError: () => {
				history.push('/applications-ora')
				message.error('Ошибка!')
			}
		}
		dispatch(equipment1Thunks.getOneEquipment(body))
	}

	useEffect(() => {
		getOneEquipment()

		if (!applicationStatus.length) dispatch(handbookThunks.getApplicationStatus())
	}, [])

	useEffect(() => {
		if (equipment.data) {
			if (type === TYPES.CREATE) {
				setStepStatus(prevState => ({
					...prevState, 1: {
						status: ACCORDION_TYPE.current,
						isOpen: true,
					},
				}))
			} else {
				let secondCheck = {
					isHasOrgs: equipment.data?.orgs?.length
				}

				let thirdCheck = {
					orgsStatus: equipment.data?.orgs.every(org => org.staus === ORGS_STATUS.APPROVED),
					harmful: equipment.data?.orgs.every(org => org.staffs?.every(staff => staff?.dicts?.harmfulCodes?.length)),
				}

				let fourthCheck = {
					status: equipment.data?.status === EQUIPMENT_STATUS.UNDER_REVIEW || (equipment.data?.status === EQUIPMENT_STATUS.SELECTED_SO && !equipment.data?.protocol)
				}

				let fifthCheck = {
					status: (equipment.data?.status === EQUIPMENT_STATUS.SELECTED_SO && equipment.data?.protocol) || equipment.data?.status === EQUIPMENT_STATUS.AT_SIGNING || equipment.data?.status === EQUIPMENT_STATUS.SIGNED
				}

				let firstCondition = true
				let secondCondition = secondCheck.isHasOrgs
				let thirdCondition = thirdCheck.orgsStatus && thirdCheck.harmful
				let fourthCondition = fourthCheck.status
				let fifthCondition = fifthCheck.status

				let checkCurrent = fifthCondition ? 5 : fourthCondition ? 4 : thirdCondition ? 3 : secondCondition ? 2 : firstCondition ? 1 : 1

				let res = Object.keys(STEPS_STATUS).reduce((acc, key) => {
					const currentKey = parseInt(key, 10);

					if (currentKey < checkCurrent) {
						acc[currentKey] = {
							status: ACCORDION_TYPE.passed,
							isOpen: false,
						}
					} else if (currentKey === checkCurrent) {
						acc[currentKey] = {
							status: ACCORDION_TYPE.current,
							isOpen: true,
						}
					} else {
						acc[currentKey] = {
							status: ACCORDION_TYPE.disabled,
							isOpen: false,
						}
					}

					return acc;
				}, {});

				setStepStatus(res)
			}
		}
	}, [equipment.data, type])

	const headerData = useMemo(() => {
		let status = applicationStatus?.find(i => i.code == equipment?.data?.status)

		let statusLabel = {
			[LANG.kz]: status?.nameKz,
			[LANG.rus]: status?.nameRu,
		}

		return [
			{ title: t('occupational_risks_assessment.bin_iin'), data: equipment?.data?.bin },
			{ title: t('full_bin_name'), data: equipment?.data?.orgName },
			{ title: t('address'), data: equipment?.data?.address },
			...(equipment?.data?.externalId ? [{ title: t('app_id'), data: equipment?.data?.externalId }] : []),
			{ title: t('pension.status_request'), data: statusLabel?.[lang] || '' },
		]
	}, [equipment?.data, t, applicationStatus, lang])

	const steps = useMemo(() => ([
		{
			title: t('occupational_risks_assessment.steps.step_1.title'),
			render: <Step1 onSuccessStep={() => handleSuccessStep()} />,
		},
		{
			title: t('occupational_risks_assessment.steps.step_2.title'),
			render: <Step2 onSuccessStep={() => handleSuccessStep()} />,
		},
		{
			title: t('occupational_risks_assessment.steps.step_3.title'),
			render: <Step3 onSuccessStep={() => handleSuccessStep()} />,
		},
		{
			title: t('occupational_risks_assessment.steps.step_4.title'),
			render: <Step4 onSuccessStep={() => handleSuccessStep()} />,
		},
		{
			title: t('occupational_risks_assessment.steps.step_5.title'),
			render: <Step5 onSuccessStep={() => handleSuccessStep()} />,
		},
	]), [equipment.data, t, stepStatus])

	const backHandler = () => {
		history.push('/applications-ora')
	}

	const handleSuccessStep = () => {
		getOneEquipment()
	}

	return (
		<>
			<RedirectUnAuthUser>
				<ProfileTemplate title={t('ckp_occupational_risks_assessment')}>
					<div className={cl('flex', 'flex-column', 'gap-4')}>
						<div>
							<div role="button" onClick={backHandler} style={{ color: '#17B67C' }}
									 className={cl('flex', 'align-center', 'gap-2')}>
								<Arrow styles={{ fill: '#17B67C', width: '5px' }} />
								{t('back')}
							</div>
						</div>

						<div>
							<DataGridTable
								hideSubTitle={true}
								hideTitle={false}
								title={'form_ora_app'}
								information={headerData || []}
								titleClass={styles.tableTitleCell}
							/>
						</div>

						<div className={cl('flex', 'flex-column', 'gap-3')}>
							{steps.map((item, index) => (
								<Accordion key={index}
													 isOpen={stepStatus[index + 1].isOpen}
													 onClick={() => {
														 if ((stepStatus[index + 1].status !== ACCORDION_TYPE.disabled)) {
															 setStepStatus(prevState => ({
																 ...prevState,
																 [index + 1]: {
																	 ...prevState[index + 1],
																	 isOpen: !prevState[index + 1].isOpen,
																 },
															 }))
														 }
													 }}
													 title={item.title}
													 icon={(
														 <div className={cl(styles.accordionIcon)}>
															 <div className={cl(styles[stepStatus[index + 1].status])}>
																 {index + 1}
															 </div>
														 </div>
													 )}
								>
									{item.render}
								</Accordion>
							))}
						</div>
					</div>

				</ProfileTemplate>
			</RedirectUnAuthUser>

			{(equipment.loading) && <LoadingBlocker />}
		</>
	)
}
