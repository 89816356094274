import { Modal, ModalBackButton } from '@/components/Modal'
import cl from 'classnames'
import styles from './Modal.module.scss'

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DropzoneFile from '@/ui/atoms/dropzoneFile'
import { Button, ConfirmModal } from '@/ui'
import { Attention } from '@app/icons'
import { useDispatch } from 'react-redux'
import { protectiveEquipment1Thunks } from '@/entity'

const MODAL_TYPE = {
	REPLACE: 'REPLACE',
	NEW: 'NEW',
}

const TYPE_KEY = {
	equipment: 'equipment',
	personalProtectionMeans: 'personalProtectionMeans',
}

const Confirm = ({
									 text,
									 submitText,
									 onCancel,
									 onSubmit,
								 }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal
			onProcess={onSubmit}
			onCancel={onCancel}
			processButtonTitle={submitText}
			cancelButtonTitle={'cancel'}
			hideBackButton
			priority={2}
		>
			<div
				style={{
					width: 388,
				}}
				className={cl('flex', 'flex-column', 'align-center', 'gap-3')}
			>
				<Attention />
				<h5>{text}</h5>
			</div>
		</ConfirmModal>
	)
}

export const ImportModal = ({
															item: currentItem,
															onClose,
															onSubmit,
														}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [uploadedFile, setUploadedFile] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const [currentType, setCurrentType] = useState(null)

	const handleSetType = (type) => {
		if (!uploadedFile) {
			setErrorMessage('Сначала выберите файл для отправки.')
			return
		}

		setCurrentType(type)
	}

	const handleSubmit = () => {
		const formData = new FormData()
		formData.append('file', uploadedFile)

		const dictName = {
			[TYPE_KEY.equipment]: 'equipment',
			[TYPE_KEY.personalProtectionMeans]: 'ppm',
		}

		const replace = {
			[MODAL_TYPE.REPLACE]: true,
			[MODAL_TYPE.NEW]: false,
		}

		let body = {
			data: {
				params: {
					dictName: dictName[currentItem.type],
					isReplace: replace[currentType],
				},
				data: formData
			},
			onSuccess: () => {
				onSubmit()
			}
		}

		dispatch(protectiveEquipment1Thunks.importEquipment(body))
	}

	const modalText = {
		[MODAL_TYPE.NEW]: t('occupational_risks_assessment.regulatory_information.new_app_notification'),
		[MODAL_TYPE.REPLACE]: t('occupational_risks_assessment.regulatory_information.replace_notification'),
	}

	const modalSubmitText = {
		[MODAL_TYPE.NEW]: 'add',
		[MODAL_TYPE.REPLACE]: 'replace',
	}

	return (
		<>
			<Modal windowClassName={cl(styles.modal_wrapper)}>
				<ModalBackButton onClick={onClose} />

				<div className={cl(styles.wrapper, 'scroll')}>
					<h4>
						{t('occupational_risks_assessment.regulatory_information.equipment_modal.title')}
					</h4>

					<DropzoneFile
						errorMessage={errorMessage}
						setErrorMessage={setErrorMessage}
						uploadedFile={uploadedFile}
						setUploadedFile={setUploadedFile}
					/>

					<div className={cl('flex', 'gap-4', 'mt-5', 'justify-content-end')}>
						<Button transparent textColor="grey" onClick={onClose} type="submit">
							{t('cancel')}
						</Button>

						<Button onClick={() => handleSetType(MODAL_TYPE.REPLACE)} disabled={!uploadedFile || errorMessage}>
							{t('occupational_risks_assessment.regulatory_information.replace_all_dict')}
						</Button>

						<Button onClick={() => handleSetType(MODAL_TYPE.NEW)} disabled={!uploadedFile || errorMessage}>
							{t('occupational_risks_assessment.regulatory_information.add_new_rec')}
						</Button>
					</div>
				</div>
			</Modal>

			{currentType &&
				<Confirm
					text={modalText?.[currentType]}
					submitText={modalSubmitText?.[currentType]}
					onCancel={() => setCurrentType(null)}
					onSubmit={handleSubmit}
				/>}
		</>
	)
}