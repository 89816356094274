import Tabs, { TabPane } from 'rc-tabs'
import { RedirectUnAuthUser } from '@/processes'
import { ProfileTemplate } from '@/widgets'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentLanguage } from '@/i18next'
import styles from './RegulatoryInformation1.module.scss'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Input } from '@/ui'
import addWhiteIcon from '@/assets/pages/personalAffairs/addWhite.svg'
import { Download } from '@app/images'
import { HrPagination } from '@/components/Pagination'
import { LoadingBlocker } from '@/components/Loader'
import { protectiveEquipment1Thunks } from '@/entity'
import { useSearchParams } from '@/shared'
import { useHistory } from 'react-router-dom'
import { Table, TableColumn } from '@/components/Table'
import { Icon } from '@/components/Icon'
import { Trash, View } from '@app/icons'
import DropDownMenu from '@/components/DropdownMenu/DropdownMenu'
import cl from 'classnames'
import { EquipmentEditModal, ImportModal, DeleteModal, PPMEquipmentEditModal } from '@features/regulatoryInformation'
import { message } from 'antd'

const ACTIONS = {
	EDIT: 'EDIT',
	CREATE: 'CREATE',
	DELETE: 'DELETE',
	IMPORT: 'IMPORT',
}

const SEARCH_PARAMS = {
	IS_DELETED: 'is_deleted',
	IS_FULL_TABLE: 'is_full_table',
}

const queryInitialState = {
	srcText: null,
	isDeleted: false,
	page: 0,
	size: 10,

	rowsPerPage: [
		{ size: 10, label: '10' },
		{ size: 30, label: '30' },
		{ size: 50, label: '50' },
		{ size: 100, label: '100' },
	],
}

const TAB_KEY = {
	equipment: 'equipment',
	personalProtectionMeans: 'personalProtectionMeans',
}

const dataInitialState = {
	data: {},
	params: {},
	payload: {},
}

const initialState = {
	item: null,
	type: null,
	action: {
		[ACTIONS.EDIT]: false,
		[ACTIONS.CREATE]: false,
		[ACTIONS.DELETE]: false,
		[ACTIONS.IMPORT]: false,
	},
}

export const RegulatoryInformation1 = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const lang = getCurrentLanguage()
	const searchParams = useSearchParams()
	const history = useHistory()

	const isDeletedSearchParams = searchParams.get(SEARCH_PARAMS.IS_DELETED)
	const isFullTableSearchParams = searchParams.get(SEARCH_PARAMS.IS_FULL_TABLE)

	const { equipmentData, downloadData } = useSelector((state) => ({
		equipmentData: state.protectiveEquipment1.equipment,
		downloadData: state.protectiveEquipment1.download,
	}))

	const [activeTab, setActiveTab] = useState(TAB_KEY.equipment)

	const [queryParams, setQueryParams] = useState(queryInitialState)

	const [modalData, setModalData] = useState(initialState)

	const getEquipment = ({ data = {}, payload = {}, params = {} } = dataInitialState) => {
		let body = {
			data, payload, params,
		}

		dispatch(protectiveEquipment1Thunks.getEquipment(body))
	}

	useEffect(() => {
		let body = {
			data: {},
			payload: {
				type: activeTab
			},
			params: {
				isDeleted: !!isDeletedSearchParams,
			},
		}
		getEquipment(body)
		setQueryParams(prevState => ({
			...prevState,
			isDeleted: !!isDeletedSearchParams,
		}))
	}, [])

	const handleChangePage = (e, page) => {
		setQueryParams({ ...queryParams, page: page - 1 })

		let params = {
			...queryParams,
			page: page - 1,
		}

		let body = {
			data: {},
			params: params,
			payload: { type: activeTab },
		}

		getEquipment(body)
	}

	const handleChangeRowsPerPage = (size) => {
		setQueryParams((prev) => ({ ...prev, page: 0, size: size }))

		let params = {
			...queryParams,
			page: 0,
			size: size,
		}

		let body = {
			data: {},
			params: params,
			payload: { type: activeTab },
		}

		getEquipment(body)
	}

	const handleChangeSearchInput = (value) => {
		setQueryParams(prevState => ({
			...prevState,
			srcText: value,
		}))
	}


	const handleChangeTab = (activeKey) => {
		setActiveTab(activeKey)

		let body = {
			data: {},
			params: {},
			payload: {
				type: activeKey,
			},
		}

		handleChangeSearchInput(null)
		getEquipment(body)
	}

	const handleSearch = () => {
		let body = {
			data: {},
			params: {
				...queryParams,
				srcText: queryParams.srcText?.length ? queryParams.srcText : null,
			},
			payload: {
				type: activeTab,
			},
		}

		getEquipment(body)
	}

	const handleChangeCheckbox = (key, value) => {
		if (value) {
			searchParams.set(key, value)
		} else {
			searchParams.delete(key)
		}

		if (key === SEARCH_PARAMS.IS_DELETED) {
			let body = {
				data: {},
				params: {
					...queryParams,
					isDeleted: value,
				},
				payload: { type: activeTab },
			}

			getEquipment(body)

			setQueryParams(prevState => ({
				...prevState,
				isDeleted: value
			}))
		}

		history.replace({ search: searchParams.toString() })
	}

	const handleClickItem = (item, type) => {
		setModalData(prevState => ({
			...prevState,
			item: item,
			type: activeTab,
			action: {
				...initialState.action,
				[type]: true,
			},
		}))
	}

	const handleCloseModal = () => {
		setModalData(initialState)
	}

	const handleDownloadDict = () => {
		let currentDictName = {
			[TAB_KEY.equipment]: 'equipment',
			[TAB_KEY.personalProtectionMeans]: 'ppm',
		}

		let params = {
			dictName: currentDictName[activeTab],
		}

		dispatch(protectiveEquipment1Thunks.getDownload(params))
	}

	const actionsRender = useCallback((record) => {
		let actions = [
			{
				icon: <Icon component={View} className={styles.icon} />,
				title: t('edit'),
				action: ACTIONS.EDIT,
			},
			{
				icon: <Icon component={Trash} className={styles.iconDelete} />,
				title: t('remove'),
				action: ACTIONS.DELETE,
			},
		]

		return (
			<DropDownMenu
				className={cl(styles.dropdown)}
				title={t('select_pls')}
				items={actions}
				onItemClick={(mode) => handleClickItem(record, mode)}
				onButtonClick={e => e.stopPropagation()}
				threeDots
			/>
		)
	}, [activeTab])

	const equipmentShortColumns = [
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.id'),
			key: 'code',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.name'),
			key: 'name',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.date_of'),
			key: 'commissioningDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.rec_status'),
			key: 'status',
			render: (item, record) => {
				return item
			},
		},
		{
			title: '',
			key: 'actionRow',
			render: (item, record) => {
				return actionsRender(record)
			},
		},
	]

	const equipmentFullColumns = [
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.id'),
			key: 'code',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.name'),
			key: 'name',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.date_of'),
			key: 'commissioningDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.service_life'),
			key: 'serviceLife',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.within_service_life'),
			key: 'isWithinServiceLife',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.create_date'),
			key: 'createDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.update_date'),
			key: 'updateDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.bin'),
			key: 'creatorEmail',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table.rec_status'),
			key: 'status',
			render: (item, record) => {
				return item
			},
		},
		{
			title: '',
			key: 'actionRow',
			render: (item, record) => {
				return actionsRender(record)
			},
		},
	]

	const personalProtectionMeansShortColumns = [
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.id'),
			key: 'code',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.name_kz'),
			key: 'nameKz',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.name_rus'),
			key: 'nameRu',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.date_create'),
			key: 'createDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.last_update'),
			key: 'updateDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.user'),
			key: 'bin',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.rec_status'),
			key: 'status',
			render: (item, record) => {
				return item
			},
		},
		{
			title: '',
			key: 'actionRow',
			render: (item, record) => {
				return actionsRender(record)
			},
		},
	]

	const personalProtectionMeansFullColumns = [
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.id'),
			key: 'code',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.name_kz'),
			key: 'nameKz',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.name_rus'),
			key: 'nameRu',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.normativ_document'),
			key: 'normativDocument',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.rate_months'),
			key: 'issueRateInMonths',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.compliant_standards'),
			key: 'isCompliantWithStandards',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.is_cert'),
			key: 'isCert',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.date_create'),
			key: 'createDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.last_update'),
			key: 'updateDate',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.user'),
			key: 'creatorEmail',
			render: (item, record) => {
				return item
			},
		},
		{
			title: t('occupational_risks_assessment.regulatory_information.info_table_protective.rec_status'),
			key: 'status',
			render: (item, record) => {
				return item
			},
		},
		{
			title: '',
			key: 'actionRow',
			render: (item, record) => {
				return actionsRender(record)
			},
		},
	]

	const tableColumn = {
		[TAB_KEY.equipment]: !isFullTableSearchParams ? equipmentFullColumns : equipmentShortColumns,
		[TAB_KEY.personalProtectionMeans]: !isFullTableSearchParams ? personalProtectionMeansFullColumns : personalProtectionMeansShortColumns,
	}

	const render = useMemo(() => {
		return (
			<div className={styles.mainWrapper}>
				<div className={styles.selectWrapper}>
					<Input
						placeholder={t('search')}
						value={queryParams.srcText}
						onChange={(e) => handleChangeSearchInput(e)}
						onKeyPress={e => {
							if (e.code === 'Enter') {
								handleSearch()
							}
						}}
					/>
					<Button onClick={handleSearch}>
						{t('find')}
					</Button>
				</div>

				<div className={styles.buttonWrapper}>
					<Button
						icon={addWhiteIcon}
						onClick={() => handleClickItem(null, ACTIONS.CREATE)}
					>
						{t('occupational_risks_assessment.regulatory_information.add_equip')}
					</Button>

					<Button
						textColor="green"
						borderColor="green"
						transparent
						border
						type="button"
						icon={Download}
						onClick={() => handleClickItem(null, ACTIONS.IMPORT)}
					>
						{t('occupational_risks_assessment.regulatory_information.import')}
					</Button>

					<Button
						textColor="green"
						borderColor="green"
						transparent
						border
						type="button"
						icon={Download}
						onClick={handleDownloadDict}
					>
						{t('occupational_risks_assessment.regulatory_information.download_dict')}
					</Button>

					<Checkbox
						onChange={(checked) => handleChangeCheckbox(SEARCH_PARAMS.IS_DELETED, checked)}
						value={!!isDeletedSearchParams}
						label={t('occupational_risks_assessment.regulatory_information.show_remove')}
					/>

					<Checkbox
						onChange={(checked) => handleChangeCheckbox(SEARCH_PARAMS.IS_FULL_TABLE, checked)}
						value={!!isFullTableSearchParams}
						label={t('occupational_risks_assessment.regulatory_information.abbreviated')}
					/>
				</div>

				<div>
					<Table
						tableName="regulatoryInformation"
						data={equipmentData.data?.content || []}
						emptyPlaceholder="-"
						tableClassName={styles.table}
						wrapperClassName={styles.tableWrapper}
					>
						{(tableColumn?.[activeTab] || []).map(item => (
							<TableColumn
								key={item.key}
								dataKey={item.key}
								heading={item.title}
								renderCell={(cell, record) => item.render ? item.render(cell, record) : cell}
							/>
						))}
					</Table>

					<HrPagination
						id="hr-pagination"
						rowsPerPageitems={queryParams.rowsPerPage}
						rowsPerPage={queryParams.size}
						numberOfElements={equipmentData?.data?.numberOfElements}
						totalElements={equipmentData?.data?.totalElements}
						totalPages={equipmentData?.data?.totalPages}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						page={queryParams.page}
					/>
				</div>
			</div>
		)
	}, [t, equipmentData, queryParams, searchParams, history])

	const tabs = [
		{
			key: TAB_KEY.equipment,
			label: t('occupational_risks_assessment.regulatory_information.equipment'),
			render: render,
		},
		{
			key: TAB_KEY.personalProtectionMeans,
			label: t('occupational_risks_assessment.regulatory_information.protective_equipment'),
			render: render,
		},
	]

	const submitOnSuccessHandler = () => {
		let body = {
			data: {},
			params: queryParams,
			payload: { type: activeTab },
		}

		getEquipment(body)
		handleCloseModal()
		message.success('Успешно!')
	}

	const handleSubmit = (data) => {
		let currentDictName = {
			[TAB_KEY.equipment]: 'equipment',
			[TAB_KEY.personalProtectionMeans]: 'ppm',
		}

		const body = {
			data: {
				data: data,
				payload: {type: currentDictName[activeTab]}
			},
			onSuccess: submitOnSuccessHandler,
		}

		dispatch(protectiveEquipment1Thunks.createEditEquipment(body))
	}


	return (
		<>
			{(modalData.action[ACTIONS.EDIT] || modalData.action[ACTIONS.CREATE]) && modalData.type === TAB_KEY.equipment &&
				<EquipmentEditModal item={modalData} onClose={handleCloseModal} onSubmit={handleSubmit} />}

			{(modalData.action[ACTIONS.EDIT] || modalData.action[ACTIONS.CREATE]) && modalData.type === TAB_KEY.personalProtectionMeans &&
				<PPMEquipmentEditModal item={modalData} onClose={handleCloseModal} onSubmit={handleSubmit}/>}

			{modalData.action[ACTIONS.IMPORT] && <ImportModal item={modalData} onClose={handleCloseModal} onSubmit={submitOnSuccessHandler}/>}

			{modalData.action[ACTIONS.DELETE] && <DeleteModal item={modalData} onClose={handleCloseModal} onSubmit={handleSubmit} />}

			<RedirectUnAuthUser>
				<ProfileTemplate title={t('occupational_risks_assessment.regulatory_information.head_title')}>
					<Tabs onChange={handleChangeTab} activeKey={activeTab}>
						{tabs.map((tab, tabIdx) => (
							<TabPane className={styles.tabPane} tab={tab.label} key={tab.key}>
								{tab.render}
							</TabPane>
						))}
					</Tabs>
				</ProfileTemplate>
			</RedirectUnAuthUser>

			{(equipmentData.loading || downloadData.loading) && <LoadingBlocker />}
		</>
	)
}